import cx from "classnames"
import React from "react"
import styles from "./button.module.scss"

interface ButtonProps {
  text: string | null
  onClick?: any
  secondary?: boolean
  disabled?: boolean
  role?: string
  id?: string
  loading?: boolean
  dataButton?: string
  small?: boolean
  className?: string
}

const Button = (props: ButtonProps) => {
  return (
    <button
      id={props.id}
      data-button={props.dataButton}
      role={props.role}
      onClick={!props.onClick ? null : props.onClick}
      className={cx(styles.button, props.className, {
        [styles["button--secondary"]]: props.secondary,
        [styles["button--small"]]: props.small,
        [styles["button--disabled"]]: props.disabled || props.loading,
      })}
      data-button-text={props.text}
      disabled={props.loading || props.disabled}
    >
      {props.loading ? "Loading..." : props.text}
    </button>
  )
}

export default Button
