import React from "react"
import styles from "./table.module.scss"
import { Scenario } from "../../_models/Scenarios"
import AlertIcon from "../../assets/alert__warning__stroke.svg"
import SuccessIcon from "../../assets/ACCC_DigitalInterface_Icons_Approved.svg"
import FailureIcon from "../../assets/ACCC_DigitalInterface_Icons_Withdrawn.svg"
import { Link } from "react-router-dom"
import TestPlanVersion from "../../_util/TestPlanVersion"
interface ScenarioTableProps {
  scenarios: Scenario[] | undefined
  participantType?: string
}

function scenarioStatus(status: string, lastModified: string) {
  let statusText: string = ""
  let statusImage: any = null
  switch (status) {
    case "InProgress":
      statusText = "In progress"
      statusImage = <img className={styles.status__icon} src={AlertIcon} alt="" />
      break
    case "NotStarted":
      statusText = "Not started"
      statusImage = null
      break
    case "Failure":
      statusText = "Failure"
      statusImage = <img className={styles.status__icon} src={FailureIcon} alt="" />
      break
    case "Interrupted":
      statusText = "Interrupted"
      statusImage = <img className={styles.status__icon} src={AlertIcon} alt="" />
      break
    default:
      statusText = "Success"
      statusImage = <img className={styles.status__icon} src={SuccessIcon} alt="" />
  }
  return (
    <div className={styles.status}>
      {statusImage}
      <span className={styles.status__text}>
        {statusText}{" "}
        {status !== "NotStarted" && "- " + new Date(lastModified).toUTCString()}
      </span>
    </div>
  )
}

function dcrStepInfo(scenarios) {
  return scenarios &&
    scenarios.some(scenario =>
      scenario.name.includes("Dynamic Client Registration (DCR)")
    ) ? (
    <span className={styles["status-info"]}>
      Run the Dynamic Client Registration scenario first. <br /> Other scenarios can
      be run in any order.
    </span>
  ) : null
}

const ScenarioTable = (props: ScenarioTableProps) => {
  const firstScenario = props.scenarios?.at(0)
  const isDHv5OrGreater =
    props.participantType === "DH" &&
    TestPlanVersion.compareVersions(firstScenario?.testPlan?.version, "5.0.0") >= 0
  return (
    <div className={styles.table__container}>
      <div className={styles.table__wrapper}>
        <table className={styles.table}>
          {!isDHv5OrGreater && (
            <caption>
              Test scenarios <span className={styles["status-info"]}></span>
              {dcrStepInfo(props.scenarios)}
            </caption>
          )}
          <thead className={styles.table__head}>
            <tr className={styles.table__row}>
              <th>Scenario</th>
              <th className={styles.table__status}>Status</th>
            </tr>
          </thead>
          <tbody>
            {props.scenarios ? (
              props.scenarios.map((scenario, idx) => (
                <tr
                  className={styles.table__row}
                  key={scenario.id}
                  id={`scenario-${idx}`}
                >
                  <td className={styles.table__columnName}>
                    <Link
                      className={styles.noBorder}
                      to={`/scenario/${scenario ? scenario.id : ""}`}
                    >
                      {scenario.name}{" "}
                      {scenario.name.includes(
                        "Dynamic Client Registration (DCR)"
                      ) ? (
                        <span>
                          - <strong> Run </strong>
                        </span>
                      ) : null}
                    </Link>
                  </td>
                  <td className={styles.table__columnStatus}>
                    <span>
                      {scenarioStatus(
                        scenario.status,
                        new Date(scenario.lastModified).toUTCString()
                      )}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>No Scenarios in Test Plan</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ScenarioTable
