import React, { useState } from "react"
import styles from "./selectbrand.module.scss"
import ButtonCard from "../../_components/ButtonCard"
import { ParticipantDetails } from "../../_services/models"
import LoadingSpinner from "../../_components/LoadingSpinner"
import Alert, { AlertType } from "../../_components/Alert"
import { useNavigate } from "react-router-dom"

interface ISelectBrandProps {
  brands: ParticipantDetails[] | undefined
  selectBrand: any
  selectedBrandName: string | undefined
}

const SelectBrand: React.FunctionComponent<ISelectBrandProps> = props => {
  let navigate = useNavigate()
  let [error, setError] = useState(null)
  let [loading] = useState(false)

  async function selectBrand(brand) {
    await props.selectBrand(brand).catch(err => setError(err))
    return navigate("/")
  }

  return (
    <div className={styles.selectBrand}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.contentWrapper}>
          <h2>Select a brand</h2>

          {localStorage.getItem("selectedBrand") ? (
            <p>
              Your currently selected brand is <b>{props.selectedBrandName}</b>.{" "}
              <br />
              To change your brand, select from one of the options below.
            </p>
          ) : (
            <p>
              To access a conformance test plan, select a brand from the options
              below.
            </p>
          )}

          {error && (
            <Alert title="Error selecting brand" alertType={AlertType.Error}>
              <p>
                Error selecting brand. Try refresh your browser. If the issue
                persists, please contact the CTS technical support team.
              </p>
            </Alert>
          )}

          <div className={styles.selectBrand__options}>
            {props.brands?.map((brand, i) => (
              <ButtonCard
                key={i}
                heading={brand.type === "DH" ? "Data Holder" : "Data Recipient"}
                subHeading={brand.name}
                subSubHeading={brand.type === "DH" && brand.sector}
                onClick={() => selectBrand(brand)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default SelectBrand
