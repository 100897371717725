import React from "react"
import styles from "./../../log.module.scss"
import SuccessIcon from "../../../../assets/ACCC_DigitalInterface_Icons_Approved.svg"
import FailureIcon from "../../../../assets/ACCC_DigitalInterface_Icons_Withdrawn.svg"
import WaitingIcon from "../../../../assets/alert__waiting__stroke.svg"
import InProgressIcon from "../../../../assets/in-progress.svg"
import NotStartedIcon from "../../../../assets/alert__not-started__stroke.svg"
import { checkIfNested } from "./LogStepName"
import { Step, StepStatus } from "../../../../_models/Scenarios"
import { statusText } from "../../../CompetenciesTable/CompetencyLogStepV2"

interface LogStepStatusProps {
  step: Step
  isStepActive: boolean
}

export const statusIcon = (status: StepStatus | null | undefined) => {
  switch (status) {
    case StepStatus.Success:
      return (
        <img
          className={styles.status__image}
          src={SuccessIcon}
          alt="Success icon"
          aria-hidden="true"
        />
      )
    case StepStatus.InProgress:
      return (
        <img
          className={styles.status__image}
          src={InProgressIcon}
          alt="In progress icon"
          aria-hidden="true"
        />
      )
    case StepStatus.Waiting:
      return (
        <img
          className={styles.status__image}
          src={WaitingIcon}
          alt="Waiting icon"
          aria-hidden="true"
        />
      )
    case StepStatus.Failure:
    case StepStatus.FailureAndContinue:
      return (
        <img
          className={styles.status__image}
          src={FailureIcon}
          alt="Failure icon"
          aria-hidden="true"
        />
      )
    case StepStatus.NotStarted:
    case undefined:
      return (
        <img
          className={styles.status__image}
          src={NotStartedIcon}
          alt="Mot Started icon"
          aria-hidden="true"
        />
      )
    default:
      return null
  }
}

const LogStepStatus = (props: LogStepStatusProps) => {
  const step = props.step

  switch (true) {
    case step.cdsRule && step.cdsRule?.title === "Awaiting inbound calls":
    case step.cdsRule &&
      step.cdsRule?.title ===
        "Awaiting on user to continue execution of test scenario":
    case step.userAction?.actionType === "WaitingContinuation":
    case step.name === "Awaiting incoming requests (may take several minutes)":
    case step.name ===
      "Awaiting incoming requests for updated data (may take several minutes)":
    case step.name ===
      "Awaiting incoming requests for updated data (may take several minutes) - ActivateSP":
    case step.name === "Authorization Request":
    case step.name ===
      "Important: Some configurations do not require all 3 tests. You can continue, only if all expected tests for your organisation have completed.":
    case step.name ===
      "Awaiting incoming requests to Get Software Product Status Api (may take up to 10 minutes)":
    case step.name ===
      "Awaiting incoming requests to Get Data Recipients Api (may take up to 10 minutes)":
      return <td />
    case step.status === StepStatus.FailureAndContinue:
      return (
        <td className={styles.step__metaData}>
          <div className={styles.step__status}>
            {statusIcon(step.status)}
            {props.isStepActive && step.userAction ? "In Progress" : "Failure"}
          </div>
          {step.status !== StepStatus.Waiting.toString() && (
            <div className={styles.step__date}>
              {new Date(props.step.executedAt).toUTCString()}
            </div>
          )}
        </td>
      )
    default:
      return (
        <td className={styles.step__metaData}>
          <div className={styles.step__status}>
            {statusIcon(step.status)}
            {props.isStepActive && step.userAction
              ? "In Progress"
              : statusText(step.status)}
          </div>
          {step.status !== StepStatus.Waiting && !checkIfNested(step) && (
            <div className={styles.step__date}>
              {new Date(props.step.executedAt).toUTCString()}
            </div>
          )}
        </td>
      )
  }
}

export default LogStepStatus
