// ********************
// Main Models
// ********************

export type TestPlan = {
  id: number
  conformanceId: string
  name: string
  status: TestPlanStatus
  scenarios: Scenario[]
  isExtant: boolean
}

export type Scenario = {
  id: number
  name: string
  status: ScenarioStatus
  steps: Step[]
  lastModified: string
  testPlan: {
    variationId: string
    version: string
  } | null
}

export type Step = {
  name: string
  status?: StepStatus | null
  details: {
    RequestResponse?: any
    Verifications?: Verification[]
  } | null
  result?: any
  cdsRule?: any
  userAction?: UserAction | null
  executedAt: string
  errorDetails?: any
  groupId?: string | null
  groupDescription?: string | null
  shouldPersist: boolean
}

export type Verification = {
  Field: string | null
  Expected: string | null
  Actual: string | null
  Description: string | null
  Outcome: string
}

// ********************
// Ancillary Models
// ********************

export type UserAction = {
  actionType: UserActionTypes
  data?: any
}

export enum UserActionTypes {
  ManualRevocation = "ManualRevocation",
  WaitingContinuation = "WaitingContinuation",
  WaitingInboundRequest = "WaitingInboundRequest",
  Redirect = "Redirect",
}

export type CdsRule = {
  title: string
  description: string
  hyperlink: string | null
}

export type Result = {
  cdsRule: CdsRule
  additionalInformation: string
}

export enum TestPlanStatus {
  InProgress = "InProgress",
  NotStarted = "NotStarted",
  Completed = "Completed",
}

export enum ScenarioStatus {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Interrupted = "Interrupted",
  Success = "Success",
  Failure = "Failure",
}

export enum StepStatus {
  NotStarted = "NotStarted",
  Success = "Success",
  Failure = "Failure",
  Inconclusive = "Inconclusive",
  InProgress = "InProgress",
  Waiting = "Waiting",
  FailureAndContinue = "FailureAndContinue",
}
