import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Authentication from "./Authentication"
import EngineRedirectPage from "./pages/EngineRedirect"
import { AuthProvider } from "react-oidc-context"

import oidcConfig from "./config"

function onSigninCallback() {
  window.location.href = "/"
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/cts/:conformanceId/dr/signin"
          element={<EngineRedirectPage />}
        />
        <Route
          path="/*"
          element={
            <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
              <Authentication />
            </AuthProvider>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default App
