import axios, { AxiosError } from "axios"
import { Service } from "./models"
import axiosRetry, { isNetworkOrIdempotentRequestError } from "axios-retry"

axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error: AxiosError<unknown, any>): boolean | Promise<boolean> => {
    if (error.request?.requestURL?.includes("/ui/scenario/")) {
      return false
    }
    return isNetworkOrIdempotentRequestError(error)
  },
})

// ******************
// Axios config - used to add access_token to header
// ******************
const config = (token?: string) =>
  token
    ? {
        headers: { Authorization: `Bearer ${token}`, pragma: "no-cache" },
      }
    : { headers: {} }

// ******************
// Generic HTTP wrapper
// ******************
// The standard methods are wrapped in handlers to allow for additional logging and metrics to be added generically
// ******************
const http = (access_token?: string) => ({
  get: (url: string, extraHeaders: object | null = null) => {
    let headers = config(access_token)
    if (extraHeaders != null) {
      headers.headers = { ...headers.headers, ...extraHeaders }
    }
    return axios.get(url, headers)
  },
  put: (url: string, data: any, extraHeaders: object | null = null) => {
    let headers = config(access_token)
    if (extraHeaders != null) {
      headers.headers = { ...headers.headers, ...extraHeaders }
    }
    return axios.put(url, data, headers)
  },
  postFormData: (url: string, data: any) =>
    axios.post(url, data, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    }),
})

// ******************
// Scenario Service Calls
// ******************
var service: Service = (access_token: string, conformanceId?: string) => {
  // All calls must be authenticated (access_token), else the calls will likely fail
  !access_token && console.error("Attempting to call service without access_token")

  const urlPrefix = process.env.REACT_APP_URL_API_PREFIX
  const baseUrl = conformanceId
    ? `${urlPrefix}/cts/${conformanceId}`
    : `${urlPrefix}/cts`
  const authenticatedHttp = http(access_token)

  return {
    // ******************
    // These can be called without a conformance ID
    // ******************
    getParticipantDetails: () => {
      const headers = {
        "x-cts-v": "3",
      }
      return authenticatedHttp.get(`${baseUrl}/participant`, headers)
    },

    // ******************
    // These need a conformance ID
    // ******************
    getTestPlans: () => {
      return conformanceId
        ? authenticatedHttp.get(`${baseUrl}/ui/test-plan`, {})
        : Promise.reject("Missing Conformance Id")
    },

    startScenario: (scenarioId: string) =>
      conformanceId
        ? authenticatedHttp.put(
            `${baseUrl}/ui/scenario/${scenarioId}`,
            {
              id: scenarioId,
              conformanceId: conformanceId,
            },
            {}
          )
        : Promise.reject("Missing Conformance Id"),

    getScenario: (scenarioId: string) =>
      conformanceId
        ? authenticatedHttp.get(`${baseUrl}/ui/scenario/${scenarioId}`, {})
        : Promise.reject("Missing Conformance Id"),

    submitTestPlan: (testPlanId: string) =>
      conformanceId
        ? authenticatedHttp.put(
            `${baseUrl}/ui/test-plan/${testPlanId}`,
            {
              testPlanId: testPlanId,
              conformanceId: conformanceId,
            },
            {}
          )
        : Promise.reject("Missing Conformance Id"),

    continueTestExecution: (scenarioId: string) =>
      conformanceId
        ? authenticatedHttp.put(
            `${baseUrl}/ui/scenario/${scenarioId}/continue`,
            {
              id: scenarioId,
              conformanceId: conformanceId,
            },
            {}
          )
        : Promise.reject("Missing Conformance Id"),

    dHInitiatedConsentRevocation: () =>
      conformanceId
        ? authenticatedHttp.get(
            `${baseUrl}/dh/connect/arrangements/dhinitiatedrevoke`,
            {}
          )
        : Promise.reject("Missing Caonformance Id"),

    sendEngineRedirect: (path: string, formData: any) =>
      http().postFormData(`${baseUrl}${path}`, formData),

    sendGetEngineRedirect: (path: string) => http().get(`${baseUrl}${path}`),
  }
}

export default service
