import React, { useState, useRef } from "react"

import styles from "./accordion.module.scss"
import accordionArrow from "../../assets/accordion-arrow.svg"

function Accordion(props) {
  const [setActive, setActiveState] = useState("")
  const [setHeight, setHeightState] = useState("0px")
  const [setRotate, setRotateState] = useState("accordion__icon")
  const [setExpanded, setExpandedState] = useState(true)

  const content = useRef<HTMLDivElement | null>(null)

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "")
    content.current &&
      setHeightState(
        setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
      )
    setRotateState(setActive === "active" ? "accordion__icon" : "rotate")
    setExpandedState(setExpanded === true ? false : true)
  }

  return (
    <div className={styles.accordion__section}>
      <button
        className={`${styles.accordion} ${styles[setActive]}`}
        onClick={toggleAccordion}
      >
        <p className={styles.accordion__title}>{props.title}</p>
        <img
          className={`${styles.accordion__icon} ${styles[setRotate]}`}
          src={accordionArrow}
          alt=""
        />
      </button>
      <div
        ref={content}
        className={styles.accordion__content}
        style={{ maxHeight: `${setHeight}` }}
        aria-hidden={setExpanded}
      >
        {props.children}
      </div>
    </div>
  )
}

export default Accordion
