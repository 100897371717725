import React from "react"
import { useAuth } from "react-oidc-context"
import AuthenticatedApp from "./AuthenticatedApp"
import LoadingSpinner from "./_components/LoadingSpinner"

function Authentication() {
  const auth = useAuth()

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>
    case "signoutRedirect":
      return <div>Signing you out...</div>
  }

  if (auth.isLoading) {
    return <LoadingSpinner />
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>
  }

  if (auth.isAuthenticated) {
    return <AuthenticatedApp />
  }

  const redirect = auth.signinRedirect()
  Promise.allSettled([redirect])

  return <></>
}

export default Authentication
