import React from "react"

const ManualRevocationNote = (props: any) => {
  return (
    <td>
      <b role="alert">Action required</b>
      <br />
      <span>You need to initiate consent withdrawal.</span>
      <br />
      <span>When this is completed the scenario will continue.</span>
    </td>
  )
}

export default ManualRevocationNote
