import React, { useState } from "react"
import Button from "../../Button"

interface WaitingContinuationNoteProps {
  isLastStep: boolean
  isScenarioInterrupted: boolean
  isSubmitLog?: boolean
  continueTestExecution?: () => void
}

const WaitingContinuationNote = (props: WaitingContinuationNoteProps) => {
  const [executing, setExecuting] = useState(false)
  return (
    <td>
      {!props.isLastStep || props.isSubmitLog || props.isScenarioInterrupted ? (
        <span>Continue test execution</span>
      ) : (
        <Button
          id="continue-test-execution-button"
          text="Continue"
          onClick={() => {
            setExecuting(true)
            if (props.continueTestExecution) {
              props.continueTestExecution()
            }
          }}
          disabled={executing}
          role="alert"
          dataButton="continue-test-execution"
          small
        />
      )}
    </td>
  )
}

export default WaitingContinuationNote
