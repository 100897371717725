import React from "react"
import cx from "classnames"
import alertInfoSolid from "../../../../assets/alert__info__solid.svg"
import { Step } from "../../../../_models/Scenarios"
import styles from "./../../log.module.scss"
import { PollingTimer } from "../../../PollingTimer"

interface LogStepNamePropsV2 {
  step: Step
  groupSteps: Step[]
  isStepActive: boolean
  isNested?: boolean
}

function getName(props: LogStepNamePropsV2) {
  if (
    props.step.name.includes("within 5 minutes") ||
    props.step.name.includes("Please ensure to continue the scenario before")
  ) {
    return (
      <PollingTimer
        step={props.step}
        groupSteps={props.groupSteps}
        scenarioIsActive={props.isStepActive}
        onCountDownFinished={undefined}
      />
    )
  }

  const isNested =
    props.isNested || props.step.name.includes("Receive request for Register")

  switch (props.step.name) {
    case "Awaiting incoming requests (may take several minutes)":
      return (
        <>
          <strong>Polling endpoints</strong>
          <div>
            <sub>Some participants do not require all 3 polling steps.</sub>
          </div>
        </>
      )
    case "Updated - Important: Some configurations do not require all 3 tests. You can continue, only if all expected tests for your organisation have completed":
      return (
        <div className={styles.step__continueWrapper}>
          <img className={styles.step__icon} src={alertInfoSolid} alt="alert icon" />
          <div>
            You can continue the scenario if the expected polling steps for your
            organisation have completed.
          </div>
        </div>
      )
    default: {
      const groupName = props.step.groupDescription
      const name = `${props.step.name}`.replace(`- ${groupName}`, "")
      return (
        <div className={cx(styles.name, isNested ? styles.isNested : "")}>
          {name}
        </div>
      )
    }
  }
}

const LogStepNameV2 = (props: LogStepNamePropsV2) => {
  return <td className={styles.step__name}>{getName(props)}</td>
}

export default LogStepNameV2
