import React from "react"
import styles from "./loadingspinner.module.scss"
import cx from "classnames"
interface LoadingSpinnerProps {
  isLogSpinner?: boolean
}

const LoadingSpinner = (props: LoadingSpinnerProps) => {
  return (
    <div
      className={cx(styles.loader, {
        [styles["loader--smallHeight"]]: props.isLogSpinner,
      })}
    >
      <div className={styles.ldsSpinner}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default LoadingSpinner
