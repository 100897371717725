/*
This is to force a proper logout of the user as the react-oidc doesnt seem to handle logouts correctly, 
it asks the user what account to logout rather than forcing a logout.
*/

const tenant =
  {
    "portal.dev.cts.cdr.gov.au": "cdrparticipantdev",
    "portal.test.cts.cdr.gov.au": "cdrparticipanttst",
    "portal.hfix.cts.cdr.gov.au": "cdrparticipantdev", // Hotfix is set to Dev
    "portal.int.cts.cdr.gov.au": "cdrparticipantint",
    "portal.cts.cdr.gov.au": "cdrparticipant",
  }[window.location.host] || "cdrparticipantdev"

export const logoutUser = (): void => {
  window.location.href = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1A_DEMO_SIGNUP_SIGNIN_TOTP&post_logout_redirect_uri=https://${window.location.host}/`
}
