export enum DataHolderScenarioName {
  DiscoveryDocumentValidation = "Discovery Document Validation",
  DynamicClientRegistration = "Dynamic Client Registration",
  ConcurrentConsent = "Concurrent Consent",
  DataHolderInitiatedRevocation = "Data Holder Initiated Revocation",
  DataRecipientInitiatedRevocation = "Data Recipient Initiated Revocation",
  RemovedSoftwareProduct = "Removed Software Product",
  ReactivateSoftwareProduct = "Reactivate Software Product",
  ReplaceExistingConsentWithPar = "Replace existing consent with PAR",
  DynamicClientRegistrationPutGet = "Dynamic Client Registration Put Get",
  DataRecipientInitiatedTokenRevocation = "Data Recipient Initiated Token Revocation",
  GetSoftwareProductStatusRegisterPolling = "Get Software Product Status Register Polling",
  EnsureClientAssertionDataInTokenRequest = "Ensure client Assertion Data In Token Request",
  AmendingAccountForAnExistingConsentScenarioWithPAR = "Amending Account For An Existing Consent Scenario with PAR",
  EnsureHolderOfKeyForResourceRequests = "Ensure Holder Of Key For Resource Requests",
  EnsureInfosecEndpointsUsingMTLS = "Ensure Infosec Endpoints Using MTLS Authentication With X.509 Certificates",
  ConsentSoftwareStatementAssertion = "Consent software statement assertion with sector identifier uri Scenario",
}

export enum DataRecipientScenarioName {
  DynamicClientRegistration = "Dynamic Client Registration (DCR)",
  OnceOffConsentGetAccounts = "Once-Off Consent (Get Accounts)",
  OngoingConsentGetAccounts = "Ongoing Consent (Get Accounts)",
  OnceOffConsentGetTransactions = "Once-Off Consent (Get Transactions)",
  OngoingConsentGetTransactions = "Ongoing Consent (Get Transactions)",
  RevokeConsentArrangement = "Revoke Consent Arrangement",
  DhInitiatedRevokeConsentArrangment = "DH Initiated Revoke Consent Arrangement",
  TokenRevocation = "Token Revocation",
  ParExtendConsent = "PAR Extend Consent",
  CoreCompetenciesScenario = "Core Competencies Scenario",
}
