import React, { useEffect, useState } from "react"
import LogStepNameV2 from "../Log/LogStep/V2/LogStepNameV2"
import LogStepStatusV2 from "../Log/LogStep/V2/LogStepStatusV2"
import { CompetencySteps } from "."
import Tooltip from "../Tooltip"
import styles from "./competenciesTable.module.scss"
import accordionArrow from "../../assets/accordion-arrow.svg"
import accordionStyles from "../Accordion/accordion.module.scss"
import { Step, StepStatus } from "../../_models/Scenarios"
import { statusIcon } from "../Log/LogStep/V1/LogStepStatus"

export function statusText(stepStatus: StepStatus | undefined | null): string {
  switch (stepStatus) {
    case StepStatus.Success:
    case StepStatus.Failure:
    case StepStatus.Waiting:
      return stepStatus.toString()
    case StepStatus.FailureAndContinue:
      return StepStatus.Failure.toString()
    case StepStatus.InProgress:
      return "In Progress"
    case StepStatus.NotStarted:
      return "Not Started"
    default:
      return "Not Started"
  }
}

export function displayTableStatus(stepStatus: StepStatus | undefined | null) {
  return (
    <span className={styles.table__status}>
      {statusIcon(stepStatus)} {statusText(stepStatus)}
    </span>
  )
}

export interface CompetencyLogStepPropsV2 {
  id: number
  competency: CompetencySteps
  competencySteps: Step[]
  isCompetencyActive: boolean
  collapseExpandAllState: boolean | undefined
  onAnyCollapseExpand: (collapse?: boolean) => void
}

function getStatusOfSteps(
  competency: CompetencySteps,
  scenarioSteps: Step[],
  isCompetencyActive: boolean
): StepStatus {
  const competencySteps = scenarioSteps
    .filter(step => step.groupDescription === competency.groupName)
    .filter(
      // Do not count inbound requests as the last step as they may appear anywhere in the order.
      step =>
        step.details?.RequestResponse?.RequestType !== "Inbound" ||
        !step.details?.RequestResponse?.Request
    )

  if (competencySteps.length === 0) {
    return StepStatus.NotStarted
  }

  const currentStep = competencySteps.reverse().at(0)

  if (isCompetencyActive && !!currentStep?.userAction) {
    return StepStatus.InProgress
  } else if (competencySteps.every(step => step?.status === StepStatus.NotStarted)) {
    return StepStatus.NotStarted
  } else if (competencySteps.every(step => step?.status === StepStatus.Success)) {
    return currentStep?.name === competency.lastStepName
      ? StepStatus.Success
      : StepStatus.InProgress
  } else if (
    competencySteps.some(
      step =>
        step?.status === StepStatus.Failure ||
        step?.status === StepStatus.FailureAndContinue
    )
  ) {
    return StepStatus.Failure
  } else if (
    competencySteps.some(step => step?.status === StepStatus.Inconclusive)
  ) {
    return StepStatus.Inconclusive
  } else if (competencySteps.some(step => step?.status === StepStatus.Waiting)) {
    return StepStatus.Waiting
  } else if (competencySteps.some(step => step?.status === StepStatus.InProgress)) {
    return StepStatus.InProgress
  }

  return StepStatus.Inconclusive
}

const CompetencyLogStepV2 = (props: CompetencyLogStepPropsV2) => {
  const [isCollapsedExpand, setIsCollapsedExpand] = useState<boolean | undefined>(
    false
  )
  const competencyId = props.id + 1

  useEffect(() => {
    if (props.collapseExpandAllState !== undefined) {
      setIsCollapsedExpand(props.collapseExpandAllState)
    }
  }, [props.collapseExpandAllState])

  function onGroupCollapsed() {
    setIsCollapsedExpand(!isCollapsedExpand)
    props.onAnyCollapseExpand(undefined)
  }

  function stepId(index: number) {
    return `${competencyId}${index + 1})`
  }

  return (
    <>
      <tr key={props.id} data-competency={props.competency.groupName}>
        <td>
          <div onClick={() => onGroupCollapsed()}>
            <span className={styles.table__info}>
              <span className={styles.table__label}>
                {props.competency.groupName}
              </span>
              <Tooltip content={props.competency.stepDescription} />
            </span>
          </div>
        </td>
        <td></td>
        <td className={styles.competencyStatus}>
          <div onClick={() => onGroupCollapsed()} className={styles.table__status}>
            {displayTableStatus(
              getStatusOfSteps(
                props.competency,
                props.competencySteps,
                props.isCompetencyActive
              )
            )}
            {props.competencySteps.length > 0 && (
              <img
                className={`${accordionStyles.accordion__icon} ${
                  accordionStyles[isCollapsedExpand ? "accordion__icon" : "rotate"]
                }`}
                src={accordionArrow}
                alt=""
              />
            )}
          </div>
        </td>
      </tr>
      {!isCollapsedExpand &&
        props.competencySteps.map((step, idx) => (
          <tr
            className={styles.competencyStep}
            key={stepId(idx)}
            data-competency-step={step.name}
          >
            <LogStepNameV2
              isNested={true}
              step={step}
              isStepActive={false}
              groupSteps={[]}
            />
            <td></td>
            <LogStepStatusV2
              step={step}
              isStepActive={
                props.isCompetencyActive && idx === props.competencySteps.length - 1
              }
            />
          </tr>
        ))}
    </>
  )
}

export default CompetencyLogStepV2
