import * as React from "react"
import { ScenarioStatus, Step } from "../../_models/Scenarios"
import TestPlanVersion from "../../_util/TestPlanVersion"
import CoreCompetenciesTable from "../CoreCompetenciesTable"
import CompetenciesTable from "."

export default class CompetenciesFactory {
  static build(
    testPlanVersion: TestPlanVersion,
    steps: Step[],
    scenarioStatus: ScenarioStatus
  ): React.JSX.Element {
    if (testPlanVersion.participantType === "DH") {
      return CompetenciesFactory.buildDHCompetencies(
        testPlanVersion,
        steps,
        scenarioStatus
      )
    }

    return CompetenciesFactory.buildAdrCompetencies(
      testPlanVersion,
      steps,
      scenarioStatus
    )
  }

  static buildDHCompetencies(
    testPlanVersion: TestPlanVersion,
    steps: Step[],
    scenarioStatus: ScenarioStatus
  ) {
    if (testPlanVersion.compareWith("5.0.0") >= 0) {
      return (
        <CompetenciesTable
          participantType={testPlanVersion.participantType}
          steps={steps}
          testPlanVersion={testPlanVersion}
          scenarioStatus={scenarioStatus}
        />
      )
    }
    return (
      <CoreCompetenciesTable
        steps={steps}
        scenarioStatus={scenarioStatus}
        testPlanVersion={testPlanVersion.version}
      />
    )
  }

  static buildAdrCompetencies(
    testPlanVersion: TestPlanVersion,
    steps: Step[],
    scenarioStatus: ScenarioStatus
  ) {
    return (
      <CoreCompetenciesTable
        steps={steps}
        scenarioStatus={scenarioStatus}
        testPlanVersion={testPlanVersion.version}
      />
    )
  }
}
