import { ParticipantDetails } from "./models"

export const selectedBrandKey = "selectedBrand"

export const getLocalBrand = async (): Promise<ParticipantDetails | null> => {
  const localBrand = localStorage.getItem(selectedBrandKey)
    ? localStorage.getItem(selectedBrandKey)
    : null

  const localBrandParsed: ParticipantDetails = localBrand
    ? JSON.parse(localBrand)
    : null

  return localBrandParsed
}

export const setSelectedBrandInStorage = (brand: ParticipantDetails) => {
  localStorage.setItem(selectedBrandKey, JSON.stringify(brand))
}

export const clearSelectedBrandInStorage = () => {
  localStorage.removeItem(selectedBrandKey)
}
