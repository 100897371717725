import React from "react"
import {
  ScenarioStatus,
  Step,
  StepStatus,
  UserActionTypes,
} from "../../../../_models/Scenarios"
import styles from "./../../log.module.scss"
import Button from "../../../Button"
import { ManualActionNote } from "../ManualActionNote"
import ManualRevocationNote from "../ManualRevocationNote"
import WaitingContinuationNote from "../WaitingContinuationNote"
import { ShowDetailsOnClickHandler } from "../V1/LogStepNote"

interface LogStepNotePropsV2 {
  step: Step
  isStepActive: boolean
  isSubmitLog?: boolean
  continueTestExecution?: () => void
  scenarioStatus?: ScenarioStatus
  browserInteract: boolean
  interactWithBrowserOnClick: () => void
  showDetailsOnClick?: ShowDetailsOnClickHandler
  index: number
}

const LogStepNoteV2 = (props: LogStepNotePropsV2) => {
  if (props.step.userAction) {
    const actionType = props.step.userAction.actionType

    if (actionType === UserActionTypes.ManualRevocation) {
      return <ManualRevocationNote />
    }

    if (actionType === UserActionTypes.WaitingContinuation) {
      return (
        <WaitingContinuationNote
          isLastStep={props.isStepActive}
          isScenarioInterrupted={props.scenarioStatus === ScenarioStatus.Interrupted}
          isSubmitLog={props.isSubmitLog}
          continueTestExecution={props.continueTestExecution}
        />
      )
    }

    const stepIsNotSuccessful = props.step.status !== StepStatus.Success
    const stepIsSuccessful = props.step.status === StepStatus.Success
    // IsManualAction = Not WaitingContinuation AND Not ManualRevocation AND Not WaitingInboundRequest AND Step is Not Success
    // Two of those cases are handled above. So we only care about WaitingInboundRequest and Success
    const isManualAction =
      (actionType !== UserActionTypes.WaitingInboundRequest &&
        stepIsNotSuccessful) ||
      (actionType === UserActionTypes.Redirect && stepIsSuccessful)
    if (isManualAction) {
      return (
        <ManualActionNote
          step={props.step}
          isLastStep={props.isStepActive}
          isScenarioInterrupted={props.scenarioStatus === ScenarioStatus.Interrupted}
          isSubmitLog={props.isSubmitLog}
          browserInteract={props.browserInteract}
          interactWithBrowserOnClick={props.interactWithBrowserOnClick}
        />
      )
    }
  }

  return (
    <td>
      {props.step.cdsRule?.title === "Awaiting inbound calls" ||
      props.step.name === "Awaiting incoming requests (may take several minutes)"
        ? ""
        : getComment(props.step, props.index, props.showDetailsOnClick)}
    </td>
  )
}

const getComment = (
  step: Step,
  index: number,
  showDetailsOnClick?: ShowDetailsOnClickHandler
) => {
  if (
    step.cdsRule?.title === "Awaiting on user to continue execution of test scenario"
  ) {
    return <Button text="Continue" disabled small />
  }

  switch (step.status) {
    case StepStatus.Failure:
    case StepStatus.FailureAndContinue:
      return (
        <>
          <span className={styles.errorDetails}>{step.errorDetails}</span>
          <br />
          <br />
          {!!showDetailsOnClick
            ? getShowDetailsButton(step, index, showDetailsOnClick)
            : null}
        </>
      )
    default:
      return (
        <>
          {!!showDetailsOnClick
            ? getShowDetailsButton(step, index, showDetailsOnClick)
            : null}
        </>
      )
  }
}

const showDetailsButton = (step: Step) => {
  return (
    step.details &&
    (step.details?.RequestResponse !== null ||
      (step.details.Verifications && step.details?.Verifications?.length > 0)) &&
    JSON.stringify(step.details) !== "{}"
  )
}

const getShowDetailsButton = (
  step: Step,
  index: number,
  showDetailsOnClick: ShowDetailsOnClickHandler
) => {
  if (showDetailsButton(step)) {
    return (
      <>
        <button
          className={styles["step__button-link"]}
          onClick={() => {
            showDetailsOnClick(step, index)
          }}
          aria-label="Show Details. Opens detail view in a new panel"
        >
          Show details
        </button>
      </>
    )
  } else if (step.executedAt === "0001-01-01T00:00:00+00:00") {
    return <></>
  } else {
    return <span className={styles.fontGray}>More details not available</span>
  }
}

export default LogStepNoteV2
