import * as React from "react"

export interface IFourZeroFourPageProps {}

export default function FourZeroFourPage(props: IFourZeroFourPageProps) {
  return (
    <div>
      <h2>404 - Cannot find page</h2>
    </div>
  )
}
