import { CompetencySteps } from "."
import { AdrCoreCompetenciesScenarioSteps } from "../../_models/AdrScenarioSteps"

//hard coded content as this does not exist in the database/backend
export const adrCoreCompetencies: CompetencySteps[] = [
  {
    groupName: "Dynamic Client Registration",
    lastStepName: AdrCoreCompetenciesScenarioSteps.CreateDhAuthRegistration,
    stepDescription:
      "the Software Product must demonstrate an ability to successfully register with a Data Holder",
    testPlanVersions: ["4."],
  },
  {
    groupName: "Establishing Consent",
    lastStepName: AdrCoreCompetenciesScenarioSteps.EstablishingConsent,
    stepDescription:
      "the Software Product must demonstrate an ability to securely obtain authorisation and consent by means of PAR, request_uri and PKCE",
    testPlanVersions: ["4."],
  },
  {
    groupName: "ADR to DH consent arrangement revocation",
    lastStepName: AdrCoreCompetenciesScenarioSteps.PostConsentRevocation,
    stepDescription:
      "the Software Product must demonstrate an ability to revoke a consumer consent arrangement from the Software Product dashboard",
    testPlanVersions: ["4."],
  },
  {
    groupName: "DH to ADR consent revocation",
    lastStepName: AdrCoreCompetenciesScenarioSteps.DhToAdrConsentRevocation,
    stepDescription:
      "the Software Product must demonstrate an ability to allow the revocation of a consumer consent arrangement when a request is received from a Data Holder",
    testPlanVersions: ["4."],
  },
]
