import React from "react"
import styles from "./toast.module.scss"

interface ToastProps {
  message: string
  show?: boolean
}

const Toast = (props: ToastProps) => {
  return (
    <div role="alert" className={`${styles.toast} ${styles.toast__show}`}>
      {props.message}
    </div>
  )
}

export default Toast
