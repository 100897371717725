import React, { useEffect, useState } from "react"
import { TestPlan } from "../../_models/Scenarios"
import styles from "./testplans.module.scss"
import Button from "../../_components/Button"
import { Navigate } from "react-router-dom"
import { AsyncStatus } from "../../_models/Status"
import { useNavigate } from "react-router-dom"
import {
  GetTestPlanDetailsResponse,
  ParticipantDetails,
} from "../../_services/models"
import ErrorMessage from "../../_components/ErrorMessage"
import Scenarios from "../../_components/Scenarios"
import LegacyScenarios from "../../_components/LegacyScenarios"
import { ParticipantType } from "../../_util/TestPlanVersion"
import {
  findInProgressScenario,
  findStartedScenario,
  getFirstInProgressTestPlan,
} from "../../AuthenticatedApp"

export interface ITestPlansPageProps {
  conformanceID: string | undefined
  brandName: string | undefined
  sector: string | undefined
  testplanStatus: AsyncStatus
  error: string | undefined
  brands: ParticipantDetails[] | undefined
  loadTestPlans: (
    conformanceId?: string
  ) => Promise<GetTestPlanDetailsResponse | undefined>
  participantType: string | undefined
  onClickHome?: () => void
}

function TestPlansPage(props: ITestPlansPageProps) {
  let navigate = useNavigate()
  const [testPlan, setTestPlan] = useState<TestPlan>()
  const [isLoading, setIsLoading] = useState(false)

  // Reload test plans each time component is rendered. Required for v4.3 and earlier
  const { loadTestPlans } = props

  useEffect(
    () => {
      setIsLoading(true)
      props.conformanceID &&
        loadTestPlans(props.conformanceID)
          .then(response => {
            const inProgressTestPlan = getFirstInProgressTestPlan(response || [])
            setTestPlan(inProgressTestPlan)
            setIsLoading(false)
          })
          .catch(() => {
            setIsLoading(false)
          })
      document.title = "Conformance Test Suite - Test Plans"
      document.getElementById("main-app-heading")?.focus()
    },
    // eslint-disable-next-line
    [] // Empty array ensures it only triggers OnMount
  )

  function redirectOnLogin(testplanState: string | undefined) {
    if (testplanState === "Completed") return <Navigate to={"submitted"} />
    else if (!localStorage.getItem("selectedBrand"))
      return <Navigate to={"select-brand"} />
    else if (!props.brandName) return <Navigate to={"select-brand"} />
  }

  const isDhSingleScenario =
    props.participantType === "DH" &&
    testPlan?.scenarios &&
    testPlan?.scenarios?.length === 1

  return (
    <div>
      {(!!testPlan && testPlan?.status === "Completed") ||
      (!localStorage.getItem("selectedBrand") &&
        props.brands &&
        props.brands.length > 1) ? (
        redirectOnLogin(testPlan?.status)
      ) : (
        <div>
          <div className={styles.testPlans__highlight}>
            <div
              className={
                (isDhSingleScenario
                  ? styles.dhContentWrapper
                  : styles.contentWrapper) +
                " " +
                styles.testPlans__brandInfo
              }
            >
              <section className={styles.testPlans__intro}>
                <div className={styles.testPlans__list}>
                  <h1>Conformance test plan</h1>
                  {props.brandName && (
                    <h2 className={styles.testPlans__brandName}>
                      {props.brandName}
                    </h2>
                  )}
                  {props.sector && (
                    <p className={styles.testPlans__sector}>{props.sector}</p>
                  )}
                  {testPlan?.name && (
                    <h3 className={styles.testPlans__testPlanName}>
                      {testPlan?.name}
                    </h3>
                  )}
                  {!isLoading &&
                    !testPlan?.isExtant &&
                    props.testplanStatus === AsyncStatus.Success && (
                      <ErrorMessage heading={""} buttonText={"OK"} isOpen={true}>
                        <div>
                          <p className={styles.testPlans__testPlanIsExtantModal}>
                            {props.participantType} Test Plan {testPlan?.name} has
                            been removed as it is no longer compliant with the
                            Consumer Data Standards.
                          </p>
                          <p className={styles.testPlans__contactOnboardingModal}>
                            Please contact Onboarding for a current test plan.
                          </p>
                        </div>
                      </ErrorMessage>
                    )}
                </div>
              </section>
              {props.brands && props.brands.length > 1 && (
                <section className={styles.testPlans__selectedBrand}>
                  <p>
                    You’re signed in as a CTS tester for <br /> {props.brandName}
                  </p>
                  <Button
                    text="Switch"
                    secondary
                    small
                    onClick={() => navigate("/select-brand")}
                  />
                </section>
              )}
            </div>
          </div>
          {!isDhSingleScenario && testPlan?.scenarios && (
            <LegacyScenarios
              scenarios={testPlan?.scenarios}
              testplanStatus={props.testplanStatus}
              testPlanIsExtant={testPlan?.isExtant}
              error={props.error}
              scenarioStarted={findStartedScenario(testPlan)}
              participantType={props.participantType}
            />
          )}
          {isDhSingleScenario && testPlan?.scenarios && (
            <Scenarios
              scenarioId={testPlan?.scenarios.at(0)?.id}
              testplanStatus={props.testplanStatus}
              testPlanIsExtant={testPlan?.isExtant}
              error={props.error}
              scenarioStarted={findStartedScenario(testPlan)}
              participantType={props.participantType as ParticipantType}
              conformanceID={props.conformanceID}
              scenarioInProgress={findInProgressScenario(testPlan)}
              onClickHome={props.onClickHome}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default TestPlansPage
