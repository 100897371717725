import { Step, StepStatus } from "../../../../_models/Scenarios"
import React from "react"
import styles from "./../../log.module.scss"
import { statusIcon } from "../V1/LogStepStatus"
import { statusText } from "../../../CompetenciesTable/CompetencyLogStepV2"

interface LogStepStatusPropsV2 {
  step: Step
  isStepActive: boolean
}

const LogStepStatusV2 = (props: LogStepStatusPropsV2) => {
  const step = props.step

  switch (true) {
    case step.cdsRule && step.cdsRule?.title === "Awaiting inbound calls":
    case step.cdsRule &&
      step.cdsRule?.title ===
        "Awaiting on user to continue execution of test scenario":
    case step.userAction?.actionType === "WaitingContinuation":
    case step.name === "Awaiting incoming requests (may take several minutes)":
    case step.name ===
      "Awaiting incoming requests for updated data (may take several minutes)":
    case step.name ===
      "Awaiting incoming requests for updated data (may take several minutes) - ActivateSP":
    case step.name === "Authorization Request":
    case step.name ===
      "Important: Some configurations do not require all 3 tests. You can continue, only if all expected tests for your organisation have completed.":
    case step.name ===
      "Awaiting incoming requests to Get Software Product Status Api (may take up to 10 minutes)":
    case step.name ===
      "Awaiting incoming requests (may take several minutes). After a Register Polling Request has been received a continue button will appear. Please ensure to continue the scenario within 5 minutes - Removed Software Product":
    case step.name ===
      "Awaiting incoming requests to Get Data Recipients Api (may take up to 10 minutes)":
      return <td />
    case step.status === StepStatus.FailureAndContinue:
      return (
        <td className={styles.step__metaData}>
          <div className={styles.step__status}>
            {statusIcon(
              props.isStepActive && step.userAction
                ? StepStatus.InProgress
                : step.status
            )}
            {props.isStepActive && step.userAction ? "In Progress" : "Failure"}
          </div>
          {step.status !== StepStatus.Waiting.toString() && (
            <div className={styles.step__date}>
              {new Date(props.step.executedAt).toUTCString()}
            </div>
          )}
        </td>
      )
    default:
      return (
        <td className={styles.step__metaData}>
          <div
            className={styles.step__status}
            title={`Executed at ${new Date(props.step.executedAt).toUTCString()}`}
          >
            {statusIcon(
              props.isStepActive && step.userAction
                ? StepStatus.InProgress
                : step.status
            )}
            {props.isStepActive && step.userAction
              ? "In Progress"
              : statusText(step.status)}
          </div>
        </td>
      )
  }
}

export default LogStepStatusV2
