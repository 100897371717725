import React, { useState, useEffect } from "react"
import { useAuth } from "react-oidc-context"

import {
  Scenario,
  Step,
  ScenarioStatus,
  UserActionTypes,
  StepStatus,
  TestPlan,
} from "../../_models/Scenarios"
import styles from "./submitted.module.scss"
import service from "../../_services/serviceCall"

import SubHeader from "../../_components/SubHeader"
import Alert, { AlertType } from "../../_components/Alert"
import Accordion from "../../_components/Accordion"
import LoadingSpinner from "../../_components/LoadingSpinner"

import cx from "classnames"
import TestPlanVersion from "../../_util/TestPlanVersion"
import { DataRecipientScenarioName } from "../../_models/ScenarioNames"
import LogFactory from "../../_components/Log/LogFactory"
import CompetenciesFactory from "../../_components/CompetenciesTable/CompetenciesFactory"
import { handle401Error } from "../../_util/error-handling"
import Button from "../../_components/Button"
import _ from "lodash"

export interface ISubmittedPageProps {
  scenarios: Scenario[] | undefined
  conformanceID: string | undefined
  testPlan: TestPlan | undefined
  testPlanName: string | undefined
  sector: string | undefined
  participantName: string | undefined
  onClickHome?: () => void
}

function hideWaitingSteps(steps: Step[], scenarioStatus: ScenarioStatus) {
  const lastStep = steps[steps.length - 1]
  if (steps.length > 1) {
    if (lastStep.userAction?.actionType === UserActionTypes.WaitingContinuation) {
      return steps
    } else if (
      lastStep.groupId &&
      steps
        .filter(step => step.groupId === lastStep.groupId)
        .every(step => step.status === StepStatus.Waiting)
    ) {
      return steps
    } else {
      if (
        scenarioStatus !== ScenarioStatus.InProgress &&
        scenarioStatus !== ScenarioStatus.NotStarted
      ) {
        return steps.filter(step => step.status !== StepStatus.Waiting)
      } else {
        return steps
      }
    }
  } else {
    return steps
  }
}

function SubmittedPage(props: ISubmittedPageProps) {
  const [scenarios, setScenarios]: [Scenario[], any] = useState([])
  const [loaded, setLoaded]: [boolean, any] = useState(false)
  const [isAdr, setIsAdr]: [boolean, any] = useState(false)
  const [error, setError] = useState<Error>()

  const auth = useAuth()

  useEffect(() => {
    async function getScenario(scenarioId) {
      return (
        auth.user &&
        service(auth.user.access_token, props.conformanceID)
          .getScenario(scenarioId)
          .then(res => {
            return res.data
          })
          .catch(err => {
            setError(err)
            err.response?.status === 401 && handle401Error(err, auth)
          })
      )
    }

    function setAllScenarios(newScenarios: Scenario[] | undefined) {
      const firstScenario =
        newScenarios && newScenarios.length > 0 && newScenarios.at(0)
      firstScenario &&
        setIsAdr(
          firstScenario.name === DataRecipientScenarioName.CoreCompetenciesScenario
        )

      setScenarios(newScenarios?.sort((a, b) => (a.id < b.id ? -1 : 1)))
    }

    if (props.scenarios) {
      const scenarioPromises = props.scenarios.map(scenario => {
        return getScenario(scenario.id)
      })
      Promise.all(scenarioPromises)
        .then(newScenarios => {
          const validScenarios: Scenario[] = newScenarios
            .filter(s => !!s)
            .map(s => s as Scenario)
          setAllScenarios(validScenarios)
          setLoaded(true)
          setError(undefined)
        })
        .catch(err => {
          setError(err)
          err.response?.status === 401 && handle401Error(err, auth)
        })
    }
    document.title = "Conformance Test Suite - Submitted"
  }, [auth, auth.user, props.conformanceID, props.scenarios])

  useEffect(() => {
    document.getElementById("main-app-heading")?.focus()
  }, [])

  return (
    <div>
      <SubHeader title={props.participantName} subtitle={props.sector} />
      <div className={styles.contentWrapper}>
        {error && (
          <div className={styles.contentWrapper}>
            <Alert title="Error loading Participant" alertType={AlertType.Error}>
              <p>{error.message || error.toString()}</p>
              <Button text="Go Back" onClick={props.onClickHome}></Button>
            </Alert>
          </div>
        )}
        {!error && loaded && (
          <div>
            <h2 id="main">Test plan submitted</h2>
            <span>
              <strong>{props.testPlanName}</strong>
            </span>
            <Alert title="Action required" alertType={AlertType.Warning}>
              <p>
                You need to email ACCC to let them know you have completed your test
                plan, along with any explanation for failed tests. To reset your test
                plan, you will need to email ACCC.
              </p>
              <p>
                <b>Email </b>
                <a href="mailto:CDRTechnicalOperations@accc.gov.au">
                  CDRTechnicalOperations@accc.gov.au
                </a>
              </p>
              <span
                className={cx(styles.submit__date, styles["submit__date--orange"])}
              ></span>
            </Alert>
            <Accordion title="Show test plan details">
              {scenarios.map((scenario, idx) => (
                <div key={idx}>
                  {(isAdr ||
                    TestPlanVersion.compareVersions(
                      scenario?.testPlan?.version || "",
                      "5.0.0"
                    ) >= 0) &&
                    scenario?.steps &&
                    CompetenciesFactory.build(
                      new TestPlanVersion(
                        scenario?.testPlan?.version || "",
                        isAdr ? "ADR" : "DH"
                      ),
                      scenario?.steps,
                      scenario?.status
                    )}
                  {LogFactory.build(
                    new TestPlanVersion(
                      scenario.testPlan?.version || "",
                      isAdr ? "ADR" : "DH"
                    ),
                    hideWaitingSteps(scenario?.steps, scenario.status),
                    scenario.name,
                    scenario.status,
                    undefined,
                    false,
                    undefined,
                    true
                  )}
                </div>
              ))}
            </Accordion>
          </div>
        )}
        {!error && !loaded && (
          <div className={styles.submitted__loading}>
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  )
}

export default SubmittedPage
