import React from "react"
import cx from "classnames"
import styles from "./../../log.module.scss"
import { Step } from "../../../../_models/Scenarios"
import LogStepNameV2 from "./LogStepNameV2"
import LogStepNoteV2 from "../V2/LogStepNoteV2"
import LogStepStatusV2 from "./LogStepStatusV2"
import { ShowDetailsOnClickHandler } from "../V1/LogStepNote"

export interface LogStepPropsV2 {
  step: Step
  groupSteps: Step[]
  id: number
  isGroupHeader: boolean
  isStepActive: boolean
  browserInteract: boolean
  interactWithBrowserOnClick: () => void
  continueTestExecution?: () => void
  showDetailsOnClick?: ShowDetailsOnClickHandler
}

const highlightStep = (step: Step, isStepActive: boolean) =>
  isStepActive &&
  (step.userAction?.actionType === "Redirect" ||
    step.userAction?.actionType === "WaitingContinuation")

const LogStepV2 = (props: LogStepPropsV2) => {
  const step = props.step
  if (props.isGroupHeader) {
    return <></>
  }
  return (
    <tr
      className={cx({
        [styles["step--highlighted"]]: highlightStep(step, props.isStepActive),
      })}
      key={props.id}
      data-step={step.name}
    >
      <LogStepNameV2
        step={step}
        groupSteps={props.groupSteps}
        isStepActive={props.isStepActive}
        isNested={false}
      />
      <td>
        {step.groupDescription
          ?.replace(" Competency", "")
          .replace("Awaiting incoming requests (may take several minutes)", "")}
      </td>
      <LogStepNoteV2
        step={step}
        isStepActive={props.isStepActive}
        browserInteract={props.browserInteract}
        interactWithBrowserOnClick={props.interactWithBrowserOnClick}
        continueTestExecution={props.continueTestExecution}
        showDetailsOnClick={props.showDetailsOnClick}
        index={props.id}
      />
      <LogStepStatusV2 step={step} isStepActive={props.isStepActive} />
    </tr>
  )
}

export default LogStepV2
