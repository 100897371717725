import React, { useEffect, useState } from "react"
import styles from "../../log.module.scss"
import { ScenarioStatus, Step } from "../../../../_models/Scenarios"

import cx from "classnames"
import LoadingSpinner from "../../../LoadingSpinner"
import { ShowDetailsOnClickHandler } from "../../LogStep/V1/LogStepNote"
import TestPlanVersion from "../../../../_util/TestPlanVersion"
import LogStepV2 from "./LogStepV2"
import { dhCompetencies } from "../../../CompetenciesTable/dhCompetencies"

const isLogStepActive = (
  step: Step,
  steps: Step[],
  scenarioStatus: ScenarioStatus | undefined
) => {
  const isScenarioActive = scenarioStatus === ScenarioStatus.InProgress
  if (!isScenarioActive) {
    return false
  }
  const competencySteps = steps.filter(
    // Removing steps with status of null (Steps that represent a group) and inbound steps so it
    // does not break the logic depending on the last step being a user action (continue button/ authorise link.
    step =>
      step.status !== null &&
      (step.details?.RequestResponse?.RequestType !== "Inbound" ||
        !step.details?.RequestResponse?.Request)
  )
  const isUserAction = !!step.userAction?.actionType
  const indexOfStep = competencySteps.findIndex(s => s.name === step.name)
  const isUserActionAndLastStep =
    isUserAction && indexOfStep + 1 >= competencySteps.length
  return isUserAction ? isUserActionAndLastStep : true
}

export interface LogV2Props {
  steps: Array<Step> | undefined
  title: string
  submitLog?: boolean
  scenarioStatus?: ScenarioStatus
  continueTestExecution?: any
  isLoading?: boolean
  showDetailsOnClick?: ShowDetailsOnClickHandler
  testPlanVersion: TestPlanVersion
}

const groupNames = new Set(dhCompetencies.map(c => c.groupName))

const LogV2 = (props: LogV2Props) => {
  const [browserInteract, setBrowserInteract] = useState<boolean>(false)

  useEffect(() => {
    props.steps &&
      browserInteract &&
      props.steps[props.steps.length - 1] &&
      setBrowserInteract(false)
  }, [props.steps, browserInteract])

  const handleInteractWithBrowserOnClick = () => {
    setBrowserInteract(true)
  }

  const checkIfComplete = (scenarioStatus: ScenarioStatus) => {
    switch (scenarioStatus) {
      case ScenarioStatus.Success:
      case ScenarioStatus.Failure:
        return true
      default:
        return false
    }
  }

  return (
    <div className={styles.log__wrapper}>
      <div className={styles.logHeader}>
        <h3
          className={cx(styles.visualyHidden, styles.logHeaderText, {
            [styles["log__heading--small"]]: props.submitLog,
          })}
        >
          {props.submitLog ? props.title : "Scenario Log"}
        </h3>
      </div>
      <div role="alert" aria-live="assertive">
        {props.scenarioStatus === ScenarioStatus.InProgress ? (
          <p role="alert" className={styles.srOnly}>
            Scenario is in progress
          </p>
        ) : null}
      </div>
      {props.isLoading ? (
        <LoadingSpinner isLogSpinner />
      ) : (
        <table>
          <caption className={styles.visualyHidden}>{props.title}</caption>
          <>
            <thead>
              <tr>
                <th className={styles.log__columnTest} scope="col">
                  Tests
                </th>
                <th className={styles.log__columnCompetency} scope="col">
                  Scenario
                </th>
                <th className={styles.log__columnNotes} scope="col">
                  Notes
                </th>
                <th className={styles.log__columnStatus} scope="col">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {props.steps && props.steps.length === 0 && (
                <tr>
                  <td>Scenario has not started </td>
                  <td />
                  <td />
                </tr>
              )}
              {props.steps &&
                props.steps.map((step, idx, array) => {
                  const isStepActive = isLogStepActive(
                    step,
                    array,
                    props.scenarioStatus
                  )
                  const isGroupHeader = groupNames.has(step?.name || "")
                  const groupSteps =
                    props.steps?.filter(
                      s => s.groupDescription === (step.groupDescription || "")
                    ) || []
                  return (
                    <LogStepV2
                      key={idx}
                      step={step}
                      id={idx}
                      isGroupHeader={isGroupHeader}
                      groupSteps={groupSteps}
                      isStepActive={isStepActive}
                      browserInteract={browserInteract}
                      interactWithBrowserOnClick={handleInteractWithBrowserOnClick}
                      continueTestExecution={props.continueTestExecution}
                      showDetailsOnClick={props.showDetailsOnClick}
                    />
                  )
                })}
              {props.scenarioStatus === ScenarioStatus.Interrupted ? (
                <tr className={styles["step--highlighted"]}>
                  <td>Scenario interrupted by user</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              ) : null}
              {props.scenarioStatus && checkIfComplete(props.scenarioStatus) ? (
                <tr className={styles["step--highlighted"]}>
                  <td>Scenario finished running</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              ) : null}
            </tbody>
          </>
        </table>
      )}
    </div>
  )
}

export default LogV2
