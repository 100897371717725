export enum AdrCoreCompetenciesScenarioSteps {
  CreateSsaJwksSign = "Create Ssa Jwks",
  CreateRegisterJwksSign = "Create Register Jwks",
  HandleRegisterGetJwks = "Get Register Jwks Uri",
  HandleSsaGetJwks = "Get SSA Jwks Uri",
  GetRegisterTokenDiscoveryDocument = "Get Register Token Discovery Document",
  GetRegisterToken = "Get Register Token",
  GetDataHolderBrands = "Get Data Holder Brands",
  GetSsa = "Get Software Statement Assertion",
  CreateDhAuthRegister = "Create Client Register",
  CreateDhAuthRegistration = "Create Client Registration",
  GetBankingAccounts = "Get Banking Accounts",
  EstablishingConsent = "Token Request", // There is no actual step for Establishing Consent, rather the consent is considered established once a token request has been made.
  PostConsentRevocation = "Post Arrangement Revocation",
  PostTokenRevocation = "Post Token Revocation",
  GetDhAuthDiscoveryDocument = "Get Dh Auth Discovery Document",
  PostTokenClientCredentials = "Token Request (Client Credentials)",
  GetDynamicClientRegistration = "Get Client Registration",
  PutDynamicClientRegistration = "Update Client Registration",
  GetDataRecipientsStatus = "Get Data Recipients Status",
  GetDataRecipients = "Get Data Recipients",
  GetSoftwareProductsStatus = "Get Software Products Status",
  PostAuthorizationRequest = "Authorization Request - POST",
  GetAuthorizationRequest = "Authorization Request - GET",
  PostPushedAuthorizationRequest = "Pushed Authorization (PAR) Request - POST",
  GetAuthorizationRequestUri = "Authorization Request (request_uri) - GET",
  GetAccessToken = "Token Request",
  PostRefreshToken = "Post Refresh Token",
  DhToAdrConsentRevocation = "Send and Verify DH to ADR Consent Arrangement Revocation",
}
