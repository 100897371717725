import * as React from "react"
import { Scenario, ScenarioStatus, Step, TestPlan } from "../../_models/Scenarios"
import service from "../../_services/serviceCall"
import styles from "./scenarios.module.scss"
import Alert, { AlertType } from "../Alert"
import LoadingSpinner from "../LoadingSpinner"
import { useAuth } from "react-oidc-context"
import LogFactory from "../Log/LogFactory"
import TestPlanVersion, { ParticipantType } from "../../_util/TestPlanVersion"
import CompetenciesFactory from "../CompetenciesTable/CompetenciesFactory"
import ScrollButton from "../ScrollButton/ScrollButton"
import { useEffect, useRef, useState, useCallback } from "react"
import { hideWaitingSteps } from "../../pages/Scenario"
import { AsyncStatus } from "../../_models/Status"
import SlidingPanel from "../SlidingPanel"
import Modal2 from "../Modal2"
import ActionBarV2 from "../ActionBarV2"
import { getFirstInProgressTestPlan } from "../../AuthenticatedApp"
import { handle401Error } from "../../_util/error-handling"
import Button from "../Button"

const pollingIntervalInMs = Number(process.env.REACT_APP_POLLING_FREQUENCY_IN_MS)

export interface IScenariosProps {
  scenarioId: number | undefined
  conformanceID: string | undefined
  scenarioStarted: boolean
  scenarioInProgress: boolean
  testPlanIsExtant: boolean | undefined
  participantType: ParticipantType
  testplanStatus: AsyncStatus
  error: string | undefined
  onClickHome?: () => void
}

const Scenarios = (props: IScenariosProps) => {
  const auth = useRef(useAuth())
  const accessToken = auth.current.user?.access_token || ""

  const [scenario, setScenario] = useState<Scenario>()
  const [isRevokeConsentLoading, setIsRevokeConsentLoading] =
    useState<boolean>(false)
  const [isStartScenarioCallLoading, setIsStartScenarioCallLoading] =
    useState<boolean>(false)
  const [isDetailsPanelOpen, setIsDetailsPanelOpen] = useState<boolean>(false)
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [currentScenarioInProgress, setCurrentScenarioInProgress] =
    useState<boolean>(false)
  const [detailsPanelSelectedStep, setDetailsPanelSelectedStep] = useState<Step>()
  const [scenarioStarted, setScenarioStarted] = useState(props.scenarioStarted)

  const isDataHolderCompetencies: boolean = props.participantType === "DH"
  const isAdrCoreCompetencies: boolean = props.participantType === "ADR"
  let pollingInterval = useRef<NodeJS.Timeout | undefined>()
  const alertRef: React.RefObject<HTMLDivElement> = React.createRef()
  const [error, setError] = useState<Error>()

  const startScenarioPolling = useCallback(
    (
      _intervalInMs: number | undefined,
      _scenarioId: number | undefined,
      _conformanceID: string | undefined,
      _accessToken: string
    ) => {
      clearInterval(pollingInterval.current)
      // Start Polling
      pollingInterval.current = setInterval(() => {
        //check for an access token when polling
        getScenario(_accessToken, _scenarioId, _conformanceID)
      }, _intervalInMs)
    },
    []
  )

  useEffect(() => {
    const scenarioId = scenario?.id ?? props.scenarioId
    clearInterval(pollingInterval.current)
    document.title = "Conformance Test Suite - Scenario"
    document.getElementById("main-app-heading")?.focus()
    if (props.conformanceID) {
      getScenario(accessToken, scenarioId, props.conformanceID)
    }

    // start polling when arriving on page if scenario in progress
    if (scenario?.status === ScenarioStatus.InProgress) {
      startScenarioPolling(
        pollingIntervalInMs,
        scenarioId,
        props.conformanceID,
        accessToken
      )
    }

    // reset focus to top of screen if scenario is successful
    if (scenario?.status === ScenarioStatus.Success) {
      document
        .getElementById("main-app-heading")
        ?.scrollIntoView({ behavior: "smooth", block: "start" })
    }

    return () => {
      clearInterval(pollingInterval.current)
    }
  }, [
    accessToken,
    props.conformanceID,
    props.scenarioId,
    scenario?.id,
    scenario?.status,
    startScenarioPolling,
  ])

  const getScenario = (
    access_token: string | undefined,
    scenarioId: number | undefined,
    conformanceId: string | undefined
  ) => {
    if (!access_token || !scenarioId) {
      return
    }

    service(access_token || "", conformanceId)
      .getScenario(`${scenarioId}`)
      .then(res => {
        setScenario(res.data)
        return res.data
      })
      .then(data => {
        setScenarioStarted(data?.status !== ScenarioStatus.NotStarted)
        setCurrentScenarioInProgress(data?.status === ScenarioStatus.InProgress)
      })
      .catch(err => {
        setError(err)
        if (err.response.status === 401) {
          handle401Error(err, auth.current)
        }
      })
  }

  const continueTestExecution = (scenarioId: any) => {
    service(accessToken, props.conformanceID).continueTestExecution(scenarioId)
  }

  //Consent Revocation for the Core Competencies Scenario for ADR's
  const dHInitiatedConsentRevocation = () => {
    setIsRevokeConsentLoading(true)
    service(accessToken || "", props.conformanceID)
      .dHInitiatedConsentRevocation()
      .then(() => setIsRevokeConsentLoading(false))
  }

  const getInProgressTestPlan = (
    conformanceID: string
  ): Promise<TestPlan | undefined> => {
    return service(accessToken || "", conformanceID)
      .getTestPlans()
      .then(res => {
        const inProgressTestPlan = getFirstInProgressTestPlan(res.data || [])
        return inProgressTestPlan
      })
      .catch(err => {
        setError(err)
        return undefined
      })
  }

  const restartScenario = (scenarioId: number, scenarioName: string) => {
    clearInterval(pollingInterval.current)
    getInProgressTestPlan(props.conformanceID || "").then(
      (testPlan: TestPlan | undefined) => {
        const scenario = testPlan?.scenarios?.find(
          s => s.id === scenarioId || s.name === scenarioName
        )
        setScenario(scenario)
        startScenario(scenario?.id, accessToken)
      }
    )
  }

  const startScenario = (scenarioId: any, access_token: string) => {
    if (!isStartScenarioCallLoading) {
      // Stop duplicate parallel calls
      setIsStartScenarioCallLoading(true)
      // Don't refresh scenario while starting the scenario
      clearInterval(pollingInterval.current)

      // Start the Scenario
      service(accessToken || "", props.conformanceID)
        .startScenario(scenarioId)
        .then(() => setModalIsOpen(false))
        .then(() => setIsStartScenarioCallLoading(false))
        .then(() => {
          return getScenario(accessToken, scenarioId, props.conformanceID)
        })
        .then(() => {
          setScenarioStarted(true)
        })
        .then(() =>
          startScenarioPolling(
            pollingIntervalInMs,
            scenarioId,
            props.conformanceID,
            accessToken
          )
        )
    }
  }

  const openModal = () => setModalIsOpen(true)

  const closeModal = () => setModalIsOpen(false)

  const getError = () => {
    return scenario?.status === ScenarioStatus.Failure
      ? scenario.steps.find(step => step.errorDetails)?.errorDetails
      : null
  }

  const lastStep = (steps: Step[]) => steps[steps.length - 1]

  const handleShowDetailsOnClick = (step: Step, _index: number) => {
    setIsDetailsPanelOpen(true)
    setDetailsPanelSelectedStep(step)
  }

  const handleCloseDetailsPanel = () => {
    setIsDetailsPanelOpen(false)
  }

  return (
    <>
      {!error && (
        <>
          <div className={styles.dhContentWrapper}>
            <h2 id="main">How to start testing</h2>
            <p className={styles.scenarioInstructions}>
              Before you begin, ensure you have made necessary configuration changes
              and conducted your own internal testing. Your test plan may include
              manual steps requiring user intervention.
            </p>
            <p className={styles.scenarioInstructions}>
              Once you complete your test scenarios, you can review your test results
              by selecting Review and submit. You should aim to pass all test
              scenarios before closing the test plan. If you believe any tests do not
              apply to your system, you must provide an explanation when submitting
              your test plan.
            </p>
          </div>
          <div className={styles.dhContentWrapper}>
            <section>
              {/* Loading Testplan */}
              {props.testplanStatus === AsyncStatus.Loading && (
                <p>Scenarios loading...</p>
              )}
              {/* Loaded Testplan */}
              {props.testplanStatus === AsyncStatus.Success && (
                <div className={styles.scenarios__table}>
                  <div className={styles.scenarioWrapper}>
                    <SlidingPanel
                      isOpen={isDetailsPanelOpen}
                      step={detailsPanelSelectedStep}
                      onClose={handleCloseDetailsPanel}
                    />

                    <Modal2
                      isOpen={modalIsOpen}
                      closeModal={() => closeModal()}
                      onRequestClose={() => closeModal()}
                      onSubmitClick={() =>
                        scenario && restartScenario(scenario.id, scenario.name)
                      }
                      heading="Interrupt a running scenario?"
                      buttonText="Yes, continue"
                    >
                      <p className={styles.centeredText}>
                        Continuing will interrupt the other scenario <br /> and
                        require it to be re-run.
                      </p>
                    </Modal2>
                    {scenario ? (
                      <div className={styles.logWrapper}>
                        <h2 id="main">
                          {scenario?.status === ScenarioStatus.InProgress
                            ? "In progress - "
                            : ""}
                          {scenario?.status === ScenarioStatus.Interrupted
                            ? "Interrupted - "
                            : ""}
                          {scenario.name}
                        </h2>

                        {scenario.status === "Failure" ||
                        scenario.status === "Success" ? (
                          <Alert
                            alertType={
                              scenario.status === ScenarioStatus.Failure
                                ? AlertType.Error
                                : AlertType.Success
                            }
                            title={`Scenario ${
                              scenario.status === ScenarioStatus.Success
                                ? "successful"
                                : "failed"
                            }`}
                            alertRef={alertRef}
                          >
                            {scenario.status === ScenarioStatus.Success ? (
                              scenario.steps.length && (
                                <p>{scenario?.steps.length} tests completed</p>
                              )
                            ) : (
                              <p>{getError()}</p>
                            )}
                            <p
                              className={
                                `${styles.alert__generated}` +
                                " " +
                                (scenario.status === ScenarioStatus.Success
                                  ? `${styles.alert__generatedSuccess}`
                                  : `${styles.alert__generatedFailure}`)
                              }
                            />
                          </Alert>
                        ) : null}

                        {(isDataHolderCompetencies || isAdrCoreCompetencies) &&
                          scenario?.steps &&
                          CompetenciesFactory.build(
                            new TestPlanVersion(
                              scenario?.testPlan?.version || "",
                              props.participantType
                            ),
                            scenario?.steps,
                            scenario?.status
                          )}
                        <div className={styles.log__container} id="log-container">
                          {LogFactory.build(
                            new TestPlanVersion(
                              scenario?.testPlan?.version || "",
                              props.participantType
                            ),
                            hideWaitingSteps(scenario?.steps, scenario.status),
                            "Scenario log",
                            scenario?.status,
                            () => continueTestExecution(scenario?.id),
                            isStartScenarioCallLoading,
                            handleShowDetailsOnClick
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className={styles.scenario__loading}>
                        <LoadingSpinner />
                      </div>
                    )}
                  </div>

                  <ScrollButton />
                </div>
              )}
              {/* Error loading Testplan */}
              {props.testplanStatus === AsyncStatus.Error && (
                <Alert title="Error loading test plan" alertType={AlertType.Error}>
                  <p>{props.error?.toString()}</p>
                </Alert>
              )}
            </section>
          </div>
          {scenario && (
            <ActionBarV2
              scenarioId={scenario.id}
              scenarioStatus={scenario.status}
              scenarioName={scenario.name}
              isRevokeConsentLoading={isRevokeConsentLoading}
              lastStep={lastStep(scenario.steps)}
              isStartScenarioCallLoading={isStartScenarioCallLoading}
              scenarioInProgress={props.scenarioInProgress}
              scenarioStarted={scenarioStarted}
              currentScenarioInProgress={currentScenarioInProgress}
              openModal={openModal}
              startScenario={restartScenario}
              consentRevocation={dHInitiatedConsentRevocation}
              testPlanIsExtant={props.testPlanIsExtant}
            />
          )}
        </>
      )}
      {error && (
        <div className={styles.contentWrapper}>
          <Alert title="Error loading Participant" alertType={AlertType.Error}>
            <p>{error.message || error.toString()}</p>
            <Button text="Go Back" onClick={props.onClickHome}></Button>
          </Alert>
        </div>
      )}
      )
    </>
  )
}

export default Scenarios
