import React, { useEffect, useState } from "react"
import styles from "./actionBar.module.scss"
import Button from "../Button"
import { ScenarioStatus, UserActionTypes, Step } from "../../_models/Scenarios"
import { DataRecipientScenarioName } from "../../_models/ScenarioNames"

import SuccessIcon from "../../assets/alert__success__stroke.svg"
import FailureIcon from "../../assets/alert__failure__stroke.svg"
import WaitingIcon from "../../assets/alert__waiting__stroke.svg"
import { useNavigate } from "react-router-dom"

interface IActionBarProps {
  scenarioStatus: string
  isStartScenarioCallLoading: boolean
  lastStep: Step
  scenarioInProgress: boolean
  scenarioStarted: boolean
  currentScenarioInProgress: boolean
  isRevokeConsentLoading: boolean
  scenarioId: number
  scenarioName: string
  consentRevocation: () => void
  openModal: () => void
  startScenario: (scenarioId: number, scenarioName: string) => void
  testPlanIsExtant: boolean | undefined
}

function buttonText(scenarioStatus: string, isStartScenarioCallLoading: boolean) {
  if (isStartScenarioCallLoading) {
    return "Loading..."
  } else {
    switch (scenarioStatus) {
      case ScenarioStatus.NotStarted:
        return "Run tests"
      default:
        return "Rerun tests"
    }
  }
}

function getScenarioStatusText(scenarioStatus: string, lastStep: Step) {
  switch (scenarioStatus) {
    case ScenarioStatus.Success:
      return (
        <span>
          <strong>Completed </strong> - successfully
        </span>
      )
    case ScenarioStatus.Failure:
      return (
        <span>
          <strong>Failed</strong> - scenario stopped
        </span>
      )
    case ScenarioStatus.InProgress:
      return (
        <span>
          <strong>In progress</strong> {getLastStepType(lastStep)}
        </span>
      )
    default:
      return null
  }
}

function getLastStepType(lastStep: Step) {
  let lastStepActionType = lastStep?.userAction?.actionType

  if (!lastStep || lastStep.groupId) {
    return "- waiting for participant"
  } else {
    switch (lastStepActionType) {
      case UserActionTypes.WaitingContinuation:
        return "- waiting for participant"
      case UserActionTypes.WaitingInboundRequest:
        return "- awaiting incoming request"
      case UserActionTypes.Redirect:
        return "- waiting for participant"
      case UserActionTypes.ManualRevocation:
        return "- waiting for participant"
      default:
        return " "
    }
  }
}

function getScenarioStatusImage(scenarioStatus: string) {
  switch (scenarioStatus) {
    case ScenarioStatus.Success:
      return SuccessIcon
    case ScenarioStatus.Failure:
      return FailureIcon
    case ScenarioStatus.InProgress:
      return WaitingIcon
    default:
      return ""
  }
}

const ActionBarV2 = (props: IActionBarProps) => {
  const navigate = useNavigate()
  const [scenarioStarted, setScenarioStarted] = useState(props.scenarioStarted)

  useEffect(() => {
    setScenarioStarted(props.scenarioStarted)
  }, [props.scenarioStarted])

  return (
    <div className={styles.actionBar}>
      <div className={styles.contentWrapper}>
        <div className={styles.actionBar__actionButtonWrapper}>
          {props.testPlanIsExtant && (
            <Button
              text={buttonText(
                props.scenarioStatus,
                props.isStartScenarioCallLoading
              )}
              secondary={scenarioStarted}
              loading={props.isStartScenarioCallLoading}
              className={styles.actionBar__scenarioButton}
              onClick={
                props.scenarioInProgress && !props.currentScenarioInProgress
                  ? () => props.openModal()
                  : () => props.startScenario(props.scenarioId, props.scenarioName)
              }
            />
          )}
          {props.testPlanIsExtant &&
            (!scenarioStarted ? (
              <Button
                text="Review and submit"
                secondary={!scenarioStarted}
                disabled={!scenarioStarted}
                className={styles.actionBar__scenarioButton}
              />
            ) : (
              <Button text="Review and submit" onClick={() => navigate("/submit")} />
            ))}
        </div>
        <div className={styles.actionBar__status}>
          <img
            className={styles.actionBar__statusImage}
            src={getScenarioStatusImage(props.scenarioStatus)!}
            alt="scenario-status"
          />
          {getScenarioStatusText(props.scenarioStatus, props.lastStep)}
        </div>
        <div className={styles.actionBar__actionButtonWrapper}>
          {props.scenarioName ===
            DataRecipientScenarioName.CoreCompetenciesScenario &&
            props.testPlanIsExtant && (
              <span>
                <b className={styles.actionBar__buttonLabel}>DH revocation:</b>
                <Button
                  text="Revoke"
                  onClick={() => props.consentRevocation()}
                  loading={props.isRevokeConsentLoading}
                  disabled={!props.currentScenarioInProgress}
                />
              </span>
            )}
        </div>
        <span className={styles.scrollbarArrowSpace}></span>
      </div>
    </div>
  )
}

export default ActionBarV2
