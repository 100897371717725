import * as React from "react"
import {
  Scenario,
  ScenarioStatus,
  Step,
  StepStatus,
  UserActionTypes,
} from "../../_models/Scenarios"
import BreadCrumbs from "../../_components/BreadCrumbs"
import ActionBar from "../../_components/ActionBar"
import service from "../../_services/serviceCall"
import styles from "./scenario.module.scss"
import Alert, { AlertType } from "../../_components/Alert"
import LoadingSpinner from "../../_components/LoadingSpinner"
import Modal2 from "../../_components/Modal2"
import SlidingPanel from "../../_components/SlidingPanel"
import SubHeader from "../../_components/SubHeader"
import { useAuth, withAuth } from "react-oidc-context"
import { withRouter } from "../../_util/withRouter"
import LogFactory from "../../_components/Log/LogFactory"
import TestPlanVersion, { ParticipantType } from "../../_util/TestPlanVersion"
import CompetenciesFactory from "../../_components/CompetenciesTable/CompetenciesFactory"
import ScrollButton from "../../_components/ScrollButton/ScrollButton"
import { useCallback, useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { handle401Error } from "../../_util/error-handling"
import Button from "../../_components/Button"

export interface IScenarioPageProps {
  participantName: string | undefined
  sector: string | undefined
  scenarios?: Scenario[]
  conformanceID: string | undefined
  scenarioInProgress: boolean
  testPlanIsExtant: boolean | undefined
  participantType: ParticipantType
  onClickHome?: () => void
}

export type ScenarioState = {
  scenario: Scenario | undefined
  isLoaded: boolean
  currentScenarioInProgress: boolean
  modalIsOpen: boolean
  focusReset: boolean
  isDetailsPanelOpen: boolean
  isRevokeConsentLoading: boolean
  isStartScenarioCallLoading: boolean // Is the Run/Rerun tests call loading
  lastStepType: UserActionTypes | undefined
  detailsPanelSelectedStep?: Step
  detailsPanelSelectedIndex?: number
}

export const hideWaitingSteps = (steps: Step[], scenarioStatus: ScenarioStatus) => {
  const lastStep = steps[steps.length - 1]

  if (lastStep) {
    if (lastStep.userAction?.actionType === UserActionTypes.WaitingContinuation) {
      return steps
    } else if (
      lastStep.groupId &&
      steps
        .filter(step => step.groupId === lastStep.groupId)
        .every(step => step.status === StepStatus.Waiting)
    ) {
      return steps
    } else {
      if (
        scenarioStatus !== ScenarioStatus.InProgress &&
        scenarioStatus !== ScenarioStatus.NotStarted
      ) {
        return steps.filter(step => step.status !== StepStatus.Waiting)
      } else {
        return steps
      }
    }
  } else return steps
}

const ScenarioPage = (props: IScenarioPageProps) => {
  const auth = useAuth()
  const { scenarioId } = useParams()
  const [scenario, setScenario] = useState<Scenario>()
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [isRevokeConsentLoading, setIsRevokeConsentLoading] =
    useState<boolean>(false)
  const [isStartScenarioCallLoading, setIsStartScenarioCallLoading] =
    useState<boolean>(false)
  const [isDetailsPanelOpen, setIsDetailsPanelOpen] = useState<boolean>(false)
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [currentScenarioInProgress, setCurrentScenarioInProgress] =
    useState<boolean>(false)
  const [detailsPanelSelectedStep, setDetailsPanelSelectedStep] = useState<Step>()
  const [isDataHolderCompetencies, setIsDataHolderCompetencies] =
    useState<boolean>(false)
  let isAdrCoreCompetencies: boolean = props.participantType === "ADR"
  let pollingInterval = useRef<NodeJS.Timeout | undefined>()
  const accessToken = auth.user?.access_token || ""
  const alertRef: React.RefObject<HTMLDivElement> = React.createRef()
  const [error, setError] = useState<Error>()

  const startScenarioPolling = useCallback(
    (
      _accessToken: string,
      _scenarioId: string,
      _conformanceId: string,
      _participantType: string
    ) => {
      clearInterval(pollingInterval.current)
      // Start Polling
      pollingInterval.current = setInterval(() => {
        //check for an access token when polling
        _accessToken &&
          getScenario(_scenarioId, _accessToken, _conformanceId, _participantType)
      }, Number(process.env.REACT_APP_POLLING_FREQUENCY_IN_MS))
    },
    []
  )

  useEffect(() => {
    document.title = "Conformance Test Suite - Scenario"
    document.getElementById("main-app-heading")?.focus()
    if (scenarioId && props.conformanceID) {
      getScenario(
        scenarioId,
        accessToken,
        props.conformanceID,
        props.participantType
      )
    }

    clearInterval(pollingInterval.current)
    // start polling when arriving on page if scenario in progress
    if (scenarioId && scenario?.status === "InProgress") {
      startScenarioPolling(
        accessToken,
        scenarioId,
        props.conformanceID || "",
        props.participantType
      )
    }

    // reset focus to top of screen if scenario is successful
    if (scenario?.status === "Success") {
      document
        .getElementById("main-app-heading")
        ?.scrollIntoView({ behavior: "smooth", block: "start" })
    }
    return () => {
      clearInterval(pollingInterval.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessToken,
    pollingInterval,
    props.conformanceID,
    scenario?.status,
    scenarioId,
    props.participantType,
  ])

  const getScenario = (
    _scenarioId: string,
    _access_Token: string,
    _conformanceId: string,
    _participantType: string
  ) => {
    service(_access_Token, _conformanceId)
      .getScenario(_scenarioId)
      .then(res => {
        const isDataHolderCompetencies =
          _participantType === "DH" &&
          TestPlanVersion.compareVersions(
            res.data?.testPlan?.version || "",
            "5.0.0"
          ) >= 0
        setIsDataHolderCompetencies(isDataHolderCompetencies)
        setScenario(res.data)
        return res.data
      })
      .then(fetchedScenario => {
        if (fetchedScenario?.status === "InProgress") {
          setCurrentScenarioInProgress(true)
          setIsLoaded(true)
        } else {
          setCurrentScenarioInProgress(false)
          setIsLoaded(true)
        }
      })
      .catch(err => {
        setError(err)
        if (err.response.status === 401) {
          handle401Error(err, auth)
        }
      })
  }

  const continueTestExecution = (scenarioId: any) => {
    service(
      auth.user?.access_token || "",
      props.conformanceID
    ).continueTestExecution(scenarioId)
  }

  //Consent Revocation for the Core Competencies Scenario for ADR's
  const dHInitiatedConsentRevocation = () => {
    setIsRevokeConsentLoading(true)
    service(auth.user?.access_token || "", props.conformanceID)
      .dHInitiatedConsentRevocation()
      .then(() => setIsRevokeConsentLoading(false))
  }

  const startScenario = (scenarioId: any) => {
    if (!isStartScenarioCallLoading) {
      // Stop duplicate parallel calls
      setIsStartScenarioCallLoading(true)
      setScenario(undefined)
      // Don't refresh scenario while starting the scenario
      clearInterval(pollingInterval.current)

      // Start the Scenario
      service(auth.user?.access_token || "", props.conformanceID)
        .startScenario(scenarioId)
        .then(() => setModalIsOpen(false))
        .then(() => setIsStartScenarioCallLoading(false))
        .then(() =>
          getScenario(
            scenarioId,
            accessToken,
            props.conformanceID || "",
            props.participantType
          )
        )
        .then(() =>
          startScenarioPolling(
            accessToken,
            scenarioId,
            props.conformanceID || "",
            props.participantType
          )
        )
    }
  }

  const openModal = () => setModalIsOpen(true)

  const closeModal = () => setModalIsOpen(false)

  const getError = () => {
    return scenario?.status === "Failure"
      ? scenario.steps.find(step => step.errorDetails)?.errorDetails
      : null
  }

  const lastStep = (steps: Step[]) => steps[steps.length - 1]

  const handleShowDetailsOnClick = (step: Step, index: number) => {
    setIsDetailsPanelOpen(true)
    setDetailsPanelSelectedStep(step)
  }

  const handleCloseDetailsPanel = () => {
    setIsDetailsPanelOpen(false)
  }

  return (
    <>
      {!error && (
        <div>
          <div className={styles.scenarioWrapper}>
            <SlidingPanel
              isOpen={isDetailsPanelOpen}
              step={detailsPanelSelectedStep}
              onClose={handleCloseDetailsPanel}
            />
            <Modal2
              isOpen={modalIsOpen}
              closeModal={() => closeModal()}
              onRequestClose={() => closeModal()}
              onSubmitClick={() => startScenario(scenario?.id)}
              heading="Interrupt a running scenario?"
              buttonText="Yes, continue"
            >
              <p className={styles.centeredText}>
                Continuing will interrupt the other scenario <br /> and require it to
                be re-run.
              </p>
            </Modal2>

            <SubHeader title={props.participantName} subtitle={props.sector} />

            {scenario ? (
              <div className={styles.contentWrapper}>
                <BreadCrumbs text={scenario ? scenario.name : null} />
                <h2 id="main">
                  {scenario?.status === "InProgress" ? "In progress - " : ""}
                  {scenario?.status === "Interrupted" ? "Interrupted - " : ""}
                  {scenario.name}
                </h2>

                {scenario.status === "Failure" || scenario.status === "Success" ? (
                  <Alert
                    alertType={
                      scenario.status === "Failure"
                        ? AlertType.Error
                        : AlertType.Success
                    }
                    title={`Scenario ${
                      scenario.status === "Success" ? "successful" : "failed"
                    }`}
                    alertRef={alertRef}
                  >
                    {scenario.status === "Success" ? (
                      scenario.steps.length && (
                        <p>{scenario?.steps.length} tests completed</p>
                      )
                    ) : (
                      <p>{getError()}</p>
                    )}
                    <p
                      className={
                        `${styles.alert__generated}` +
                        " " +
                        (scenario.status === "Success"
                          ? `${styles.alert__generatedSuccess}`
                          : `${styles.alert__generatedFailure}`)
                      }
                    />
                  </Alert>
                ) : null}

                {(isDataHolderCompetencies || isAdrCoreCompetencies) &&
                  scenario?.steps &&
                  CompetenciesFactory.build(
                    new TestPlanVersion(
                      scenario?.testPlan?.version || "",
                      props.participantType
                    ),
                    scenario?.steps,
                    scenario?.status
                  )}
                <div className={styles.log__container} id="log-container">
                  {LogFactory.build(
                    new TestPlanVersion(
                      scenario?.testPlan?.version || "",
                      props.participantType
                    ),
                    hideWaitingSteps(scenario?.steps, scenario.status),
                    "Scenario log",
                    scenario?.status,
                    () => continueTestExecution(scenario?.id),
                    isStartScenarioCallLoading,
                    handleShowDetailsOnClick,
                    false
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.scenario__loading}>
                <LoadingSpinner />
              </div>
            )}
          </div>
          {scenario && (
            <ActionBar
              scenarioId={scenario.id}
              scenarioStatus={scenario.status}
              scenarioName={scenario.name}
              isRevokeConsentLoading={isRevokeConsentLoading}
              lastStep={lastStep(scenario.steps)}
              isStartScenarioCallLoading={isStartScenarioCallLoading}
              scenarioInProgress={props.scenarioInProgress}
              currentScenarioInProgress={currentScenarioInProgress}
              openModal={openModal}
              startScenario={startScenario}
              consentRevocation={dHInitiatedConsentRevocation}
              testPlanIsExtant={props.testPlanIsExtant}
            />
          )}

          <ScrollButton />
        </div>
      )}
      {error && (
        <div className={styles.contentWrapper}>
          <Alert title="Error loading Participant" alertType={AlertType.Error}>
            <p>{error.message || error.toString()}</p>
            <Button text="Go Back" onClick={props.onClickHome}></Button>
          </Alert>
        </div>
      )}
    </>
  )
}

export default withRouter(withAuth(ScenarioPage))
