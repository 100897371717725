import React, { useEffect, useState } from "react"
import { MINUTE, StepTimer, TIME_MULTIPLIER } from "../StepTimer"
import { Step, StepStatus, UserActionTypes } from "../../_models/Scenarios"

const defaultDurationInMin =
  Number(process.env.REACT_APP_POLLING_TIMER_DURATION_IN_SEC || 300) /
  TIME_MULTIPLIER

const isCountingDownActive = (groupSteps: Step[]) => {
  const continueIndex = groupSteps.findIndex(
    s =>
      s.userAction?.actionType === UserActionTypes.WaitingContinuation ||
      s.name ===
        "Updated - Important: Some configurations do not require all 3 tests. You can continue, only if all expected tests for your organisation have completed"
  )
  if (continueIndex < 0) {
    return groupSteps.some(g => g.status === StepStatus.Waiting)
  }
  const continueStep = groupSteps[continueIndex]
  return !continueStep || continueIndex + 1 === groupSteps.length
}

interface IPollingTimerProps {
  step: Step
  groupSteps: Array<Step>
  scenarioIsActive: boolean
  onCountDownFinished?: () => void | undefined
}

export const PollingTimer = (props: IPollingTimerProps) => {
  const executedDate = new Date(props.step.executedAt)
  const [deadline] = useState<Date | undefined>(
    new Date(+executedDate + defaultDurationInMin * MINUTE)
  )
  const [isCountingDown, setIsCountingDown] = useState<boolean>()

  useEffect(() => {
    const isCounting = isCountingDownActive(props.groupSteps)
    setIsCountingDown(isCounting)
  }, [props.groupSteps])

  return (
    <>
      <strong>Awaiting incoming requests</strong>
      <div>
        Awaiting incoming requests (may take several minutes). After a Register
        Polling Request has been received a continue button will appear. Please
        ensure to continue the scenario before{" "}
        {isCountingDown ? (
          <StepTimer
            deadline={deadline}
            active={props.scenarioIsActive}
            onDeadlined={() => {
              if (props.onCountDownFinished) {
                props.onCountDownFinished()
              }
            }}
          />
        ) : (
          `${defaultDurationInMin} minutes`
        )}
      </div>
    </>
  )
}
