import React from "react"
import { Scenario } from "../../_models/Scenarios"
import styles from "./legacy-scenarios.module.scss"
import Button from "../../_components/Button"
import ScenarioTable from "../../_components/ScenarioTable"
import { AsyncStatus } from "../../_models/Status"
import Alert, { AlertType } from "../../_components/Alert"
import { useNavigate } from "react-router-dom"

export interface ITestPlansPageProps {
  scenarios?: Scenario[]
  testplanStatus: AsyncStatus
  testPlanIsExtant: boolean | undefined
  error: string | undefined
  scenarioStarted: boolean
  participantType: string | undefined
}

function LegacyScenarios(props: ITestPlansPageProps) {
  const navigate = useNavigate()

  return (
    <>
      <div className={styles.contentWrapper}>
        <h2 id="main">How to start testing</h2>
        <p>
          Before you begin, ensure you have made necessary configuration changes and
          conducted your own internal testing. Your test plan is made up of a number
          of test scenarios. Some scenarios may include manual steps. Select the test
          scenario you would like to run.
        </p>
        <p>
          Once you complete your test scenarios, you can review your test results by
          selecting <strong>Review and submit</strong>. You should aim to pass all
          test scenarios before closing the test plan. If you believe any tests do
          not apply to your system, you must provide an explanation when submitting
          your test plan.
        </p>
      </div>
      <div className={styles.contentWrapper}>
        <section>
          {/* Loading Testplan */}
          {props.testplanStatus === AsyncStatus.Loading && (
            <p>Scenarios loading...</p>
          )}
          {/* Loaded Testplan */}
          {props.testplanStatus === AsyncStatus.Success && (
            <div className={styles.scenarios__table}>
              <ScenarioTable
                scenarios={props.scenarios}
                participantType={props.participantType}
              />
              <div className={styles.testplan__buttons}>
                {props.testPlanIsExtant === true ? (
                  !props.scenarioStarted ? (
                    <Button
                      text="Review and submit"
                      secondary={true}
                      disabled={props.scenarioStarted}
                    />
                  ) : (
                    <Button
                      text="Review and submit"
                      onClick={() => navigate("/submit")}
                    />
                  )
                ) : (
                  <p />
                )}
              </div>
            </div>
          )}
          {/* Error loading Testplan */}
          {props.testplanStatus === AsyncStatus.Error && (
            <Alert title="Error loading test plan" alertType={AlertType.Error}>
              <p>{props.error?.toString()}</p>
            </Alert>
          )}
        </section>
      </div>
    </>
  )
}

export default LegacyScenarios
