import React from "react"
import Logo from "../../assets/CDR_Logo_Primary_White.svg"
import styles from "./header.module.scss"
import { Link } from "react-router-dom"
import Button from "../Button"

interface HeaderProps {
  onClickLogout?: () => void
  onClickHome?: () => void
}

const Header = (props: HeaderProps) => {
  return (
    <header className={styles.header}>
      <div className={styles.header__container} id="app-header">
        <div className={styles.header__columnLogo}>
          <Link to="/select-brand" onClick={props.onClickHome}>
            <img
              className={styles.logo}
              src={Logo}
              alt="Consumer Data Right logo"
              id="cdr-logo"
            />
          </Link>
        </div>
        <h1 className={styles.heading__main} tabIndex={-1} id="main-app-heading">
          Conformance Test Suite{" "}
        </h1>
        <a className={styles.skipLink} href="#main" id="skip-to-content">
          Skip to content
        </a>
        <Button text="Sign out" onClick={props.onClickLogout} />
      </div>
    </header>
  )
}

export default Header
