import { CompetencySteps } from "."
import { DhCompetenciesScenarioSteps } from "../../_models/DhScenarioSteps"

//hard coded content as this does not exist in the database/backend
export const dhCompetencies: CompetencySteps[] = [
  {
    groupName: "Discovery Document",
    lastStepName: DhCompetenciesScenarioSteps.DiscoveryCompetency,
    stepDescription:
      "The Data Holder participant must demonstrate an ability to correctly handle a discovery document request from a Participant ADR.",
    testPlanVersions: ["5."],
  },
  {
    groupName: "Ensure Infosec Endpoints Using MTLS",
    lastStepName: DhCompetenciesScenarioSteps.MTLSCompetency,
    stepDescription:
      "The Data Holder participant must demonstrate an ability to correctly handle an invalid client certificate received from a Participant ADR.",
    testPlanVersions: ["5."],
  },
  {
    groupName: "Ensure SSA Validation",
    lastStepName: DhCompetenciesScenarioSteps.EnsureSsaValidation,
    stepDescription:
      "The Data Holder participant must demonstrate the ability to correctly handle an invalid SSA received from a participant ADR",
    testPlanVersions: ["5.0.1"],
  },
  {
    groupName: "Create Client Registration",
    lastStepName: DhCompetenciesScenarioSteps.ClientRegistration,
    stepDescription:
      "The Data Holder participant must demonstrate an ability to register the CTS simulated ADR’s software product.",
    testPlanVersions: ["5."],
  },
  {
    groupName: "First Consent",
    lastStepName: DhCompetenciesScenarioSteps.FirstConsentCompetence,
    stepDescription:
      "The Data Holder participant must demonstrate an ability to establish consent with the CTS simulated ADR’s software product.",
    testPlanVersions: ["5."],
  },
  {
    groupName: "Holder Of Key Resource Requests",
    lastStepName: DhCompetenciesScenarioSteps.HOKResourceCompetency,
    stepDescription:
      "The Data Holder participant must demonstrate an ability to reject resource requests with an invalid certificate",
    testPlanVersions: ["5."],
  },
  {
    groupName: "Second Consent",
    lastStepName: DhCompetenciesScenarioSteps.SecondConsentCompetency,
    stepDescription:
      "The Data Holder participant must demonstrate an ability to establish a concurrent consent with the CTS simulated ADR’s software product and generate a unique arrangement id for the second consent.",
    testPlanVersions: ["5."],
  },
  {
    groupName: "Data Recipient Initiated Arrangement Revocation",
    lastStepName:
      DhCompetenciesScenarioSteps.ADRInitiatedConsentRevocationCompetency,
    stepDescription:
      "The Data Holder participant must demonstrate an ability to revoke consent when a consent revocation request is initiated by the CTS simulated ADR’s software product.",
    testPlanVersions: ["5."],
  },
  {
    groupName: "Amending Existing Consent",
    lastStepName: DhCompetenciesScenarioSteps.AmendedConsentCompetency,
    stepDescription:
      "The Data Holder participant must demonstrate an ability to replace an existing consent arrangement and correctly respond to a token request with an invalid refresh token.",
    testPlanVersions: ["5."],
  },
  {
    groupName: "Data Recipient Initiated Token Revocation",
    lastStepName: DhCompetenciesScenarioSteps.TokenRevocationCompetency,
    stepDescription:
      "The Data Holder participant must demonstrate an ability to revoke a token when a token revocation request is initiated by the CTS simulated ADR’s software product.",
    testPlanVersions: ["5."],
  },
  {
    groupName: "Data Holder Initiated Arrangement Revocation",
    lastStepName: DhCompetenciesScenarioSteps.DHInitiatedConsentRevocationCompetency,
    stepDescription:
      "The Data Holder participant must demonstrate an ability to send a valid consent revocation request to the simulated ADR’s software product.",
    testPlanVersions: ["5."],
  },
  {
    groupName: "Ensure Client Assertion Data in Requests",
    lastStepName: DhCompetenciesScenarioSteps.ClientAssertionCompetency,
    stepDescription:
      "The Data Holder participant must demonstrate an ability to reject poorly formed access token requests from the CTS Simulated ADR’s software product.",
    testPlanVersions: ["5."],
  },
  {
    groupName: "Retrieve and Update Client Registration",
    lastStepName:
      DhCompetenciesScenarioSteps.ClientRegistrationGetandUpdateCompetency,
    stepDescription:
      "The Data Holder participant must demonstrate an ability to modify and return the client registration when requested by the CTS Simulated ADR’s software product.",
    testPlanVersions: ["5."],
  },
  {
    groupName: "Removed Software Product",
    lastStepName:
      DhCompetenciesScenarioSteps.RegisterRemovedSoftwareProductCompetency,
    stepDescription:
      "The Data Holder participant must demonstrate an ability to poll the CTS Register within 5 minutes for a Participant ADR’s Software Product Status changes and be able to reject requests when the status of the CTS simulated ADR’s software product changes to ‘REMOVED’.",
    testPlanVersions: ["5."],
  },
]
