import * as React from "react"
import { ScenarioStatus, Step } from "../../_models/Scenarios"
import { ShowDetailsOnClickHandler } from "./LogStep/V1/LogStepNote"
import TestPlanVersion from "../../_util/TestPlanVersion"
import LogV2 from "./LogStep/V2/LogV2"
import Log from "."

export default class LogFactory {
  static build(
    testPlanVersion: TestPlanVersion,
    steps: Array<Step> | undefined,
    title: string,
    scenarioStatus?: ScenarioStatus,
    continueTestExecution?: any,
    isLoading?: boolean,
    showDetailsOnClick?: ShowDetailsOnClickHandler,
    submitLog?: boolean
  ) {
    if (testPlanVersion.compareWith("5.0.0") >= 0) {
      return (
        <LogV2
          title={title}
          steps={steps}
          scenarioStatus={scenarioStatus}
          continueTestExecution={continueTestExecution}
          isLoading={isLoading}
          showDetailsOnClick={showDetailsOnClick}
          testPlanVersion={testPlanVersion}
          submitLog={submitLog}
        />
      )
    }
    return (
      <Log
        title={title}
        steps={steps}
        scenarioStatus={scenarioStatus}
        continueTestExecution={continueTestExecution}
        isLoading={isLoading}
        showDetailsOnClick={showDetailsOnClick}
        submitLog={submitLog}
      />
    )
  }
}
