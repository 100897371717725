import React, { useEffect, useState } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import TestPlansPage from "./pages/TestPlans"
import ScenarioPage from "./pages/Scenario"
import SubmitPage from "./pages/Submit"
import SubmittedPage from "./pages/Submitted"
import FourZeroFourPage from "./pages/FourZeroFour"
import service from "./_services/serviceCall"
import packageJson from "../package.json"
import { ScenarioStatus, TestPlan, TestPlanStatus } from "./_models/Scenarios"
import { AsyncStatus } from "./_models/Status"
import LoadingSpinner from "./_components/LoadingSpinner"
import Alert, { AlertType } from "./_components/Alert"
import { logoutUser } from "./_util/logoutUser"
import SelectBrand from "./pages/SelectBrand"
import {
  GetParticipantDetailsResponse,
  GetTestPlanDetailsResponse,
  ParticipantDetails,
} from "./_services/models"
import { useIdleTimer } from "react-idle-timer"
import { useAuth } from "react-oidc-context"
import Header from "./_components/Header"
import styles from "./app.module.scss"
import {
  clearSelectedBrandInStorage,
  getLocalBrand,
  setSelectedBrandInStorage,
} from "./_services/localStorage"
import Button from "./_components/Button"
import { handle401Error } from "./_util/error-handling"
import { AxiosError } from "axios"

export const getFirstInProgressTestPlan = (
  testPlans: TestPlan[]
): TestPlan | undefined => {
  let selectedPlan, inProgressPlan

  //check for an inProgress plan
  inProgressPlan =
    testPlans?.length &&
    testPlans.find(testPlan => testPlan.status === TestPlanStatus.InProgress)

  // if the plan is inProgress set the plan
  if (inProgressPlan) {
    selectedPlan = testPlans?.length
      ? testPlans.filter(
          testPlan => testPlan.status === TestPlanStatus.InProgress
        )[0]
      : undefined
  } else {
    // Otherwise grab the most recently completed plan
    selectedPlan = testPlans?.length
      ? testPlans.filter(testPlan => testPlan.status === TestPlanStatus.Completed)[0]
      : undefined
  }
  return selectedPlan
}

export const findStartedScenario = (selectedPlan: TestPlan | undefined) => {
  let scenarioStarted = false
  if (
    selectedPlan &&
    selectedPlan.scenarios.find(
      scenario => scenario.status !== ScenarioStatus.NotStarted
    )
  ) {
    scenarioStarted = true
  }
  return scenarioStarted
}

export const findInProgressScenario = (selectedPlan: TestPlan | undefined) => {
  let scenarioInProgress = false
  if (
    selectedPlan &&
    selectedPlan.scenarios.find(
      scenario => scenario.status === ScenarioStatus.InProgress
    )
  ) {
    scenarioInProgress = true
  }
  return scenarioInProgress
}

const AuthenticatedApp = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const [testPlans, setTestPlans] = useState<GetTestPlanDetailsResponse>([])
  const [conformanceId, setConformanceId] = useState<string>()
  const [brandName, setBrandName] = useState<string>()
  const [sector, setSector] = useState<string>()
  const [brands, setBrands] = useState<any[]>()
  const [participantStatus, setParticipantStatus] = useState<AsyncStatus>(
    AsyncStatus.Loading
  )
  const [testPlanStatus, setTestPlanStatus] = useState<AsyncStatus>(
    AsyncStatus.NotStarted
  )
  const [error, setError] = useState<any>()

  const onIdle = () => {}

  const onActive = () => {}

  const onAction = () => {}

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: Number(process.env.REACT_APP_IDLE_TIMEOUT_IN_MS),
    throttle: 500,
  })

  useEffect(() => {
    // On load of page, load participant details then get test plans
    const hasTestPlans = testPlans && testPlans?.length > 0
    !hasTestPlans && loadPageData(auth.user?.access_token || "", sector, brands)

    // Logout user if they are idle for 20 minutes when the token is 5 minutes from expiry
    // else refresh the token using signinSilent()
    auth.events.addAccessTokenExpiring(() => {
      getRemainingTime() > 0
        ? auth.signinSilent()
        : auth.removeUser().then(() => logoutUser())
    })
    // Log app version
    console.log("App Version: ", packageJson.version)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadPageData = (
    accessToken: string,
    _sector: string | undefined,
    _brands: any[] | undefined
  ) => {
    return getParticipantDetails(accessToken, _brands).then(
      ({ status, confID, activeBrandName }) => {
        status === AsyncStatus.Success &&
          activeBrandName &&
          getTestPlans(accessToken, confID, _brands, _sector, activeBrandName)
      }
    )
  }

  const getSelectedBrandFromResponse = (
    currentBrand: ParticipantDetails | null,
    brandResponse: GetParticipantDetailsResponse | undefined
  ) => {
    if (currentBrand && brandResponse) {
      return brandResponse?.find(
        d => d.type === currentBrand.type && d.name === currentBrand.name
      )
    } else if (brandResponse?.length === 1) {
      return brandResponse.at(0)
    }
    return undefined
  }

  const getParticipantDetails = async (
    accessToken: string,
    _brands: any[] | undefined
  ) => {
    return service(accessToken, undefined)
      .getParticipantDetails()
      .then(res => {
        return checkLocalBrand(_brands).then(currentBrand => {
          const selectedBrand = getSelectedBrandFromResponse(currentBrand, res.data)

          selectedBrand && setBrandDetails(selectedBrand)
          setParticipantStatus(AsyncStatus.Success)
          setBrands(res.data || [])
          return {
            status: AsyncStatus.Success,
            confID: selectedBrand?.conformanceId,
            activeBrandName: selectedBrand?.name,
          }
        })
      })
      .catch((err: AxiosError) => {
        setTestPlanStatus(AsyncStatus.Error)
        setError(err)
        err.response?.status === 401 && handle401Error(error, auth)
        return {
          status: AsyncStatus.Error,
          confID: undefined,
          activeBrandName: undefined,
        }
      })
  }

  const setBrandDetails = (brand: ParticipantDetails) => {
    setConformanceId(brand.conformanceId)
    setBrandName(brand.name)
    setSector(brand.sector)
    setSelectedBrandInStorage(brand)
  }

  const selectBrand = async (brand: ParticipantDetails) => {
    return getTestPlans(
      auth.user?.access_token || "",
      brand.conformanceId,
      brands,
      sector,
      undefined
    )
      .then(res => {
        setBrandDetails(brand)
      })
      .catch(err => {
        setTestPlanStatus(AsyncStatus.Error)
        setError(err)
        err.response?.status === 401 && handle401Error(error, auth)
        return undefined
      })
  }

  const checkLocalBrand = async (_brands): Promise<ParticipantDetails | null> => {
    const localBrand = await getLocalBrand()
    if (localBrand) {
      // Check if the email address matches to the currently signed in user
      const localEmailAddress = localBrand.email
      const signedInUserEmailAddress = _brands?.[0].email
      if (localEmailAddress !== signedInUserEmailAddress) {
        clearSelectedBrandInStorage()
        return null
      }
    }

    return localBrand
  }

  const clearSelectedBrand = () => {
    clearSelectedBrandInStorage()
    setConformanceId(undefined)
    setError(undefined)
    setBrandName(undefined)
    setSector(undefined)
    setTestPlans([])
    setTestPlanStatus(AsyncStatus.NotStarted)
  }

  const goBackAndReloadData = () => {
    clearSelectedBrand()
    navigate("/")
    loadPageData(auth.user?.access_token || "", sector, brands)
  }

  const getTestPlans = async (
    accessToken: string,
    _conformanceId: string | undefined,
    _brands: any[] | undefined,
    _sector: string | undefined,
    selectedBrandName?: string | undefined
  ): Promise<GetTestPlanDetailsResponse | undefined> => {
    setTestPlanStatus(AsyncStatus.Loading)
    // check if a brand has been saved locally and load that
    let localBrand = await checkLocalBrand(_brands)
    if (localBrand && !selectedBrandName) {
      setConformanceId(localBrand.conformanceId)
      setBrandName(localBrand.name)
      setSector(localBrand.sector)
      _conformanceId = localBrand.conformanceId
    }
    return service(accessToken, _conformanceId)
      .getTestPlans()
      .then(res => {
        const testPlans = res.data || []
        setTestPlans(testPlans)
        setTestPlanStatus(
          testPlans.length > 0 ? AsyncStatus.Success : AsyncStatus.Error
        )
        setBrandName(selectedBrandName ?? localBrand?.name)
        setSector(localBrand ? localBrand.sector : _sector)
        return res.data
      })
      .catch(err => {
        setTestPlanStatus(AsyncStatus.Error)
        setError(err)
        err.response?.status === 401 && handle401Error(error, auth)
        return undefined
      })
  }

  const onClickLogout = () => {
    // The react-oidc lib's signOut function doesn't work, calling these two guys does.
    auth.removeUser()
    auth.signoutRedirect()
  }

  const firstInProgressTestPlan = getFirstInProgressTestPlan(testPlans)
  const isError =
    participantStatus === AsyncStatus.Error ||
    testPlanStatus === AsyncStatus.Error ||
    !!error

  return (
    <div className="App">
      <Header onClickLogout={onClickLogout} onClickHome={goBackAndReloadData} />
      <main>
        {
          // Load participant first always
          isError ? (
            <div className={styles.contentWrapper}>
              <Alert title="Error loading Participant" alertType={AlertType.Error}>
                <p>{error?.message || error?.toString()}</p>
                <Button text="Go Back" onClick={goBackAndReloadData}></Button>
              </Alert>
            </div>
          ) : participantStatus === AsyncStatus.Success ? (
            // Then show the pages once participant details are loaded
            <Routes>
              <Route
                path="/scenario/:scenarioId"
                element={
                  <ScenarioPage
                    participantName={brandName}
                    sector={sector}
                    scenarios={firstInProgressTestPlan?.scenarios}
                    conformanceID={conformanceId}
                    scenarioInProgress={findInProgressScenario(
                      firstInProgressTestPlan
                    )}
                    testPlanIsExtant={firstInProgressTestPlan?.isExtant}
                    participantType={
                      brands?.find(x => x.name === brandName)?.type || ""
                    }
                    onClickHome={goBackAndReloadData}
                  />
                }
              ></Route>
              <Route
                path="/submit"
                element={
                  <SubmitPage
                    participantName={brandName}
                    scenarios={firstInProgressTestPlan?.scenarios}
                    conformanceID={conformanceId}
                    sector={sector}
                    testPlanId={firstInProgressTestPlan?.id}
                    testPlanName={firstInProgressTestPlan?.name}
                    scenarioStarted={findStartedScenario(firstInProgressTestPlan)}
                    onClickHome={goBackAndReloadData}
                  />
                }
              ></Route>
              <Route
                path="/submitted"
                element={
                  <SubmittedPage
                    participantName={brandName}
                    scenarios={firstInProgressTestPlan?.scenarios}
                    conformanceID={conformanceId}
                    sector={sector}
                    testPlan={firstInProgressTestPlan}
                    testPlanName={firstInProgressTestPlan?.name}
                    onClickHome={goBackAndReloadData}
                  />
                }
              ></Route>
              <Route
                path="/select-brand"
                element={
                  <SelectBrand
                    brands={brands}
                    selectBrand={selectBrand}
                    selectedBrandName={brandName}
                  />
                }
              ></Route>
              <Route
                path="/"
                element={
                  <TestPlansPage
                    conformanceID={conformanceId}
                    brandName={brandName}
                    sector={sector}
                    testplanStatus={testPlanStatus}
                    error={error}
                    brands={brands}
                    loadTestPlans={() =>
                      getTestPlans(
                        auth.user?.access_token || "",
                        conformanceId,
                        brands,
                        sector,
                        brandName
                      )
                    }
                    participantType={brands?.find(x => x.name === brandName)?.type}
                    onClickHome={goBackAndReloadData}
                  />
                }
              ></Route>
              {/* // TODO - handle random routes to this 404 page */}
              <Route element={<FourZeroFourPage />} />
            </Routes>
          ) : (
            <LoadingSpinner />
          )
        }
      </main>
    </div>
  )
}

export default AuthenticatedApp
