import React, { useRef, useState } from "react"
import styles from "./slidingpanel.module.scss"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { Step, StepStatus } from "../../_models/Scenarios"
import Button from "../Button"
import Toast from "../Toast"
import FocusTrap from "focus-trap-react"
import customStyle from "./customStyle"
import cx from "classnames"
interface SlidingPanelProps {
  isOpen: boolean
  step?: Step
  onClose: () => void
}

export function cleanJson(json: any) {
  return JSON.parse(
    removeNewLineCharacters(JSON.stringify(stripNullAndUndefined(json)))
  )
}

function removeNewLineCharacters(jsonString: string): string {
  return jsonString.replace(/\\+n|\\+r/gi, "")
}

export function IsJsonString(str) {
  try {
    if (str == null) {
      return false
    }
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const stripNullAndUndefined = (obj: object): object => {
  if (typeof obj === "object") {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName]
      } else if (Array.isArray(obj[propName])) {
        obj[propName] = obj[propName].filter(e => e !== null && e !== undefined)
        obj[propName].forEach(element => {
          if (typeof element === "object") {
            stripNullAndUndefined(element)
          }
        })
      } else if (typeof obj[propName] === "object") {
        stripNullAndUndefined(obj[propName])
        for (var subProp in obj[propName]) {
          obj[propName][subProp] = IsJsonString(obj[propName][subProp])
            ? JSON.parse(obj[propName][subProp])
            : obj[propName][subProp]
        }
      }
    }
  }

  return obj
}

const SlidingPanel = (props: SlidingPanelProps) => {
  const codeRef = useRef(null)
  const [shouldClose, setShouldClose] = useState(false)
  const [showCopyToast, setShouldCopyToast] = useState(false)
  const onClose = () => {
    setShouldClose(true)
    props.onClose()
  }
  const copyFunction = () => {
    // @ts-ignore
    navigator.clipboard.writeText(JSON.stringify(stepDetails))
    setShouldCopyToast(true)
    setTimeout(() => {
      setShouldCopyToast(false)
    }, 3000)
  }

  const notOpenClass = shouldClose ? styles.sliding__panel__container__exit : ""
  const contentNotOpenClass = shouldClose ? styles.sliding__panel__content_exit : ""
  const headerNotOpenClass = shouldClose ? styles.sliding__panel__header__exit : ""

  const stepDetails =
    props.step?.details?.Verifications?.length === 0
      ? {
          RequestResponse: cleanJson(props.step?.details?.RequestResponse ?? null),
        }
      : {
          RequestResponse: cleanJson(props.step?.details?.RequestResponse ?? null),
          Verifications: props.step?.details?.Verifications?.map(verification => {
            if (verification.Description != null) {
              return {
                ...verification,
                Expected: IsJsonString(verification.Expected)
                  ? JSON.parse(verification.Expected ?? "")
                  : verification.Expected,
                Actual: IsJsonString(verification.Actual)
                  ? JSON.parse(verification.Actual ?? "")
                  : verification.Actual,
                Description: IsJsonString(verification.Description)
                  ? JSON.parse(verification.Description ?? "")
                  : verification.Description,
              }
            } else {
              return {
                Field: verification.Field,
                Expected: IsJsonString(verification.Expected)
                  ? JSON.parse(verification.Expected ?? "")
                  : verification.Expected,
                Actual: IsJsonString(verification.Actual)
                  ? JSON.parse(verification.Actual ?? "")
                  : verification.Actual,
                Outcome: verification.Outcome,
              }
            }
          }),
        }

  return (
    <FocusTrap active={props.isOpen}>
      <div
        className={cx(styles.sliding__panel, {
          [styles.sliding__panel__container__active]: props.isOpen,
          [notOpenClass]: !props.isOpen,
        })}
      >
        <div className={styles.slidingPanelBlockout} onClick={onClose} />
        <div
          className={`${styles.sliding__panel__header} ${
            props.isOpen ? styles.sliding__panel__header__active : headerNotOpenClass
          }`}
        >
          <h2 aria-live="assertive" aria-label="Detailed view panel">
            Detailed view
          </h2>
          <Button text={"Close"} secondary={true} onClick={onClose} />
        </div>
        <div
          className={`${styles.sliding__panel__content} ${
            props.isOpen
              ? styles.sliding__panel__content__active
              : contentNotOpenClass
          }`}
        >
          <h3
            className={
              props.step?.status === StepStatus.Failure
                ? styles.failure__background
                : styles.success__background
            }
          >
            {props.step?.name}
          </h3>
          {props.step?.errorDetails ? <p>{props.step?.errorDetails}</p> : null}
          <div className={styles.btn__container}>
            <button className={styles.btn__copy} onClick={copyFunction}>
              Copy
            </button>
          </div>
          <SyntaxHighlighter
            language="json"
            codeTagProps={{ ref: codeRef }}
            wrapLongLines={true}
            lineProps={{ style: { whiteSpace: "pre" } }}
            showLineNumbers={true}
            style={customStyle}
          >
            {JSON.stringify(stepDetails, undefined, "\t").replace(/\\/g, "")}
          </SyntaxHighlighter>
        </div>
        {showCopyToast ? <Toast message={"Copied!"} /> : null}
      </div>
    </FocusTrap>
  )
}

export default SlidingPanel
