import { AuthProviderProps } from "react-oidc-context"

// *******************************************
// Client Id based on host, default to dev
// *******************************************
const client_id =
  {
    "portal.dev.cts.cdr.gov.au": "52fb1f10-588f-44de-9ac0-fbeff3557cc1",
    "portal.test.cts.cdr.gov.au": "8c3f2de0-a5e1-41c8-ab79-3c1aea14b8c9",
    "portal.hfix.cts.cdr.gov.au": "4d56e5d5-770b-4a3a-a897-3ff5479ce38e",
    "portal.preprod.cts.cdr.gov.au": "f2d29dc4-4545-47a6-aecc-7c8265d845a1",
    "portal.cts.cdr.gov.au": "5176fc4f-7f7c-4d70-93d0-b4123ca50bb7",
  }[window.location.host] || "52fb1f10-588f-44de-9ac0-fbeff3557cc1"
// *******************************************
// Tenant based on host, default to dev
// *******************************************
const tenant =
  {
    "portal.dev.cts.cdr.gov.au": "cdrparticipantdev",
    "portal.test.cts.cdr.gov.au": "cdrparticipanttst",
    "portal.hfix.cts.cdr.gov.au": "cdrparticipanthfix",
    "portal.preprod.cts.cdr.gov.au": "cdrparticipantint",
    "portal.cts.cdr.gov.au": "cdrparticipant",
  }[window.location.host] || "cdrparticipantdev"

const userManagerConfig: AuthProviderProps = {
  authority: `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/B2C_1A_DEMO_SIGNUP_SIGNIN_TOTP/v2.0`,
  redirect_uri: `https://${window.location.host}/callback`,
  popup_redirect_uri: `https://${window.location.host}/callback`,
  response_type: "code", // Automatically uses Auth Code PKCE flow
  client_id,
  scope: `https://${tenant}.onmicrosoft.com/${client_id}/user.read`,
  response_mode: "query",

  automaticSilentRenew: false, // Auto-renew token before expiry (Azure AD default is 1hr)
  accessTokenExpiringNotificationTimeInSeconds: 300,
  revokeTokensOnSignout: true,
  post_logout_redirect_uri: "https://www.cdr.gov.au/",
  loadUserInfo: false,
}

export default userManagerConfig
