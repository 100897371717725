import React from "react"
import styles from "./breadcrumbs.module.scss"
import { Link } from "react-router-dom"

export interface IBreadCrumbsProps {
  text: string | null
}

const Breadcrumbs = (props: IBreadCrumbsProps) => {
  return (
    <div className={styles.breadcrumbs}>
      <Link className={styles.breadcrumb} to="/">
        Test plan
      </Link>
      <span className={styles.arrow}>{">"}</span>
      <span className={styles.breadcrumb}>{props.text}</span>
    </div>
  )
}

export default Breadcrumbs
