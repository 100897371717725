export enum DhCompetenciesScenarioSteps {
  DiscoveryCompetency = "Verify discovery document - Discovery Document",
  MTLSCompetency = "Verify bad client registration request response for revoked certificate - Ensure Infosec Endpoints Using MTLS",
  EnsureSsaValidation = "Verify bad client registration request response for invalid SSA - Ensure SSA Validation",
  ClientRegistration = "Verify client registration - Create Client Registration",
  FirstConsentCompetence = "Verify customer response - First Consent",
  HOKResourceCompetency = "Verify customers request with wrong client certificate - Holder Of Key Resource Requests",
  SecondConsentCompetency = "Verify arrangement id - Second Consent",
  ADRInitiatedConsentRevocationCompetency = "Verify bad request token response - Data Recipient Initiated Arrangement Revocation",
  AmendedConsentCompetency = "Verify bad request token response - Amending Existing Consent",
  TokenRevocationCompetency = "Verify bad request token response - Data Recipient Initiated Token Revocation",
  DHInitiatedConsentRevocationCompetency = "Return 204 No Content response - Data Holder Initiated Arrangement Revocation",
  ClientAssertionCompetency = "Verify bad request token response with wrong client assertion type - Ensure Client Assertion Data in Requests",
  ClientRegistrationGetandUpdateCompetency = "Verify get client registration client id - Retrieve and Update Client Registration",
  RegisterRemovedSoftwareProductCompetency = "Verify consent revocation response when software product is removed - Removed Software Product",
}
