import React from "react"
import { AdrCoreCompetenciesScenarioSteps } from "../../_models/AdrScenarioSteps"
import { ScenarioStatus, Step } from "../../_models/Scenarios"
import styles from "./coreCompetenciesTable.module.scss"
import Tooltip from "../../_components/Tooltip"
import { hideWaitingSteps } from "../../pages/Scenario"
import TestPlanVersion from "../../_util/TestPlanVersion"
import { displayTableStatus } from "../CompetenciesTable/CompetencyLogStepV2"

interface CoreCompetenciesTableProps {
  steps?: Step[]
  scenarioStatus: ScenarioStatus
  testPlanVersion: string | undefined
}

function findLastOccurenceOfStep(
  stepName: AdrCoreCompetenciesScenarioSteps,
  scenarioSteps: Step[]
) {
  return scenarioSteps.filter(step => step.name === stepName).pop()
}

function getCompetenciesForTestPlan(
  testPlanVersion: string | undefined
): CoreCompetencySteps[] {
  const tpVersion = testPlanVersion ?? ""
  return coreCompetencies.filter(c =>
    c.testPlanVersions.some(
      compVersion =>
        compVersion.length === 0 ||
        TestPlanVersion.compareVersions(tpVersion, compVersion) >= 0
    )
  )
}

interface CoreCompetencySteps {
  stepName: AdrCoreCompetenciesScenarioSteps
  stepDisplayName: string
  stepDescription: string
  testPlanVersions: string[]
}

//hard coded content as this does not exist in the database/backend
const coreCompetencies: CoreCompetencySteps[] = [
  {
    stepName: AdrCoreCompetenciesScenarioSteps.CreateDhAuthRegistration,
    stepDisplayName: "Dynamic Client Registration",
    stepDescription:
      "the Software Product must demonstrate an ability to successfully register with a Data Holder",
    testPlanVersions: ["4."],
  },
  {
    stepName: AdrCoreCompetenciesScenarioSteps.EstablishingConsent,
    stepDisplayName: "Establishing Consent",
    stepDescription:
      "the Software Product must demonstrate an ability to securely obtain authorisation and consent by means of PAR, request_uri and PKCE",
    testPlanVersions: ["4."],
  },
  {
    stepName: AdrCoreCompetenciesScenarioSteps.PostConsentRevocation,
    stepDisplayName: "ADR to DH consent arrangement revocation",
    stepDescription:
      "the Software Product must demonstrate an ability to revoke a consumer consent arrangement from the Software Product dashboard",
    testPlanVersions: ["4."],
  },
  {
    stepName: AdrCoreCompetenciesScenarioSteps.DhToAdrConsentRevocation,
    stepDisplayName: "DH to ADR consent revocation",
    stepDescription:
      "the Software Product must demonstrate an ability to allow the revocation of a consumer consent arrangement when a request is received from a Data Holder",
    testPlanVersions: ["4."],
  },
]

const CoreCompetenciesTable = (props: CoreCompetenciesTableProps) => {
  return (
    <section className={styles.scenario__content}>
      <p className={styles["long-paragraph"]}>
        <b>
          To successfully complete conformance testing, you must complete the
          competencies displayed below:
        </b>
      </p>
      {props.steps && (
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Competencies</th>
              <th></th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {getCompetenciesForTestPlan(props.testPlanVersion).map(
              (competency, i) => (
                <tr key={i}>
                  <td>
                    <span className={styles.table__info}>
                      <span className={styles.table__label}>
                        {competency.stepDisplayName}
                      </span>
                      <Tooltip content={competency.stepDescription} />
                    </span>
                  </td>
                  <td></td>
                  <td className={styles.table__status}>
                    {displayTableStatus(
                      findLastOccurenceOfStep(
                        competency.stepName,
                        hideWaitingSteps(props.steps || [], props.scenarioStatus)
                      )?.status
                    )}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      )}
      <p className={styles["long-paragraph"]}>
        You may complete them in any order that your application supports, however,
        the steps taken to reach these competencies will need to adhere to the
        security profile described in the Consumer Data Standards and Register
        Design.
      </p>
      <p className={styles["long-paragraph"]}>
        If the participant use-case doesn't support one or more of the competencies,
        you can submit your test plan without 100% completion and justify the
        use-case upon submission.
      </p>
    </section>
  )
}

export default CoreCompetenciesTable
