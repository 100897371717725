import cx from "classnames"
import React from "react"
import { Step } from "../../../../_models/Scenarios"
import LogStepName from "./LogStepName"
import LogStepNote, { ShowDetailsOnClickHandler } from "./LogStepNote"
import LogStepStatus from "./LogStepStatus"
import styles from "./../../log.module.scss"

export interface LogStepProps {
  step: Step
  id: number
  isStepActive: boolean
  browserInteract: boolean
  interactWithBrowserOnClick: () => void
  continueTestExecution?: () => void
  showDetailsOnClick?: ShowDetailsOnClickHandler
}

const highlightStep = (step: Step, isStepActive: boolean) =>
  isStepActive &&
  (step.userAction?.actionType === "Redirect" ||
    step.userAction?.actionType === "WaitingContinuation")

const LogStep = (props: LogStepProps) => {
  const step = props.step
  return (
    <tr
      className={cx({
        [styles["step--highlighted"]]: highlightStep(step, props.isStepActive),
      })}
      key={props.id}
      data-step={step.name}
    >
      <LogStepName step={step} />
      <LogStepNote
        step={step}
        isStepActive={props.isStepActive}
        browserInteract={props.browserInteract}
        interactWithBrowserOnClick={props.interactWithBrowserOnClick}
        continueTestExecution={props.continueTestExecution}
        showDetailsOnClick={props.showDetailsOnClick}
        index={props.id}
      />
      <LogStepStatus step={step} isStepActive={props.isStepActive} />
    </tr>
  )
}

export default LogStep
