export type IsEqualCompareType = 1 | 0 | -1
export type ParticipantType = "DH" | "ADR"

export default class TestPlanVersion {
  constructor(public version: string, public participantType: ParticipantType) {}

  public static compareVersions(
    version1?: string,
    version2?: string
  ): IsEqualCompareType {
    const v1 = version1?.split(".") || []
    const v2 = version2?.split(".") || []
    var longestLength = v1.length > v2.length ? v1.length : v2.length
    for (var i = 0; i < longestLength; i++) {
      // eslint-disable-next-line eqeqeq
      if (v1[i] != v2[i]) {
        return v1 > v2 ? 1 : -1
      }
    }
    return 0
  }

  // Returns 0 if both versions are the same
  // Returns -1 if the compareVersion is greater
  // Returns 1 if this.version is larger
  public compareWith(compareVersion?: string): IsEqualCompareType {
    return TestPlanVersion.compareVersions(this.version, compareVersion)
  }
}
