import React from "react"
import styles from "./buttonCard.module.scss"

interface ButtonCardProps {
  onClick: any
  heading: string
  subHeading: string
  subSubHeading: any
}

function ButtonCard(props: ButtonCardProps) {
  return (
    <button
      className={styles.card__button}
      onClick={!props.onClick ? null : props.onClick}
    >
      <div className={styles.card__content}>
        <p className={styles.card__type}>{props.heading}</p>
        <p className={styles.card__name}>{props.subHeading}</p>
        <p className={styles.card__sector}>{props.subSubHeading}</p>
      </div>
    </button>
  )
}

export default ButtonCard
