import React from "react"
import Tippy from "@tippyjs/react"
import "tippy.js/dist/tippy.css"
import { Placement } from "@popperjs/core"

import alertInfoIcon from "../../assets/alert__info__solid.svg"
import styles from "./tooltip.module.scss"

interface ToolTipProps {
  content?: string
  placement?: Placement
}

const Tooltip = (props: ToolTipProps) => (
  <Tippy placement={props.placement} content={props.content}>
    <img className={styles.tooltip__icon} src={alertInfoIcon} alt="tooltip" />
  </Tippy>
)

Tooltip.defaultProps = {
  placement: "right",
  content: "Default tooltip content",
}

export default Tooltip
