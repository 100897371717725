import React, { useState } from "react"
import { ScenarioStatus, Step } from "../../_models/Scenarios"
import styles from "./competenciesTable.module.scss"
import { dhCompetencies } from "./dhCompetencies"
import { adrCoreCompetencies } from "./adrCoreCompetencies"
import TestPlanVersion, { ParticipantType } from "../../_util/TestPlanVersion"
import CompetencyLogStepV2 from "./CompetencyLogStepV2"
import cx from "classnames"
import { hideWaitingSteps } from "../../pages/Scenario"

interface CompetenciesTableProps {
  participantType: ParticipantType
  steps: Step[]
  testPlanVersion: TestPlanVersion
  scenarioStatus: ScenarioStatus
}

function getCompetenciesForTestPlan(
  participantType: ParticipantType,
  testPlanVersion: string | undefined,
  scenarioSteps: Step[]
): { competency: CompetencySteps; steps: Step[] }[] {
  const tpVersion = testPlanVersion || ""
  const competencies =
    participantType === "DH" ? dhCompetencies : adrCoreCompetencies
  return competencies
    .filter(c =>
      c.testPlanVersions.some(
        compVersion =>
          compVersion.length === 0 ||
          TestPlanVersion.compareVersions(tpVersion, compVersion) >= 0
      )
    )
    .map(c => {
      return {
        competency: c,
        steps: scenarioSteps.filter(s => c.groupName === s.groupDescription || ""),
      }
    })
}

export interface CompetencySteps {
  groupName: string
  lastStepName: string
  stepDescription: string
  testPlanVersions: string[]
}

const CompetenciesTable = (props: CompetenciesTableProps) => {
  const [collapseExpandAllState, setCollapsedExpandAllState] = useState<
    boolean | undefined
  >(true)

  function onAnyCollapseExpand(collapsed?: boolean) {
    setCollapsedExpandAllState(collapsed)
  }

  const competencies = getCompetenciesForTestPlan(
    props.participantType,
    props.testPlanVersion.version,
    hideWaitingSteps(props.steps || [], props.scenarioStatus)
  )
  const activeCompetencyName = props.steps.at(
    props.steps.length - 1
  )?.groupDescription
  return (
    <section className={styles.scenario__content}>
      <p className={styles["long-paragraph"]}>
        <b>
          To successfully complete conformance testing, you must complete the test
          scenarios displayed below:
        </b>
      </p>
      {props.steps && (
        <>
          <div className={styles.competenciesHeader}>
            {
              <div
                onClick={() => {
                  setCollapsedExpandAllState(!collapseExpandAllState)
                }}
                className={cx(
                  styles.competenciesToggle,
                  styles.accordionToggle,
                  collapseExpandAllState
                    ? styles.competenciesToggle__expand
                    : styles.competenciesToggle__collapse
                )}
              >
                {collapseExpandAllState
                  ? "Expand all sections"
                  : "Collapse all sections"}
              </div>
            }
          </div>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Scenario</th>
                <th> </th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {competencies.map((competencyGroup, idx) => (
                <CompetencyLogStepV2
                  id={idx}
                  key={idx}
                  competency={competencyGroup.competency}
                  competencySteps={competencyGroup.steps}
                  isCompetencyActive={
                    props.scenarioStatus === ScenarioStatus.InProgress &&
                    activeCompetencyName === competencyGroup.competency.groupName
                  }
                  collapseExpandAllState={collapseExpandAllState}
                  onAnyCollapseExpand={onAnyCollapseExpand}
                />
              ))}
            </tbody>
          </table>
        </>
      )}
      <p className={styles["long-paragraph"]}>
        Your application must adhere to the security profile described in Consumer
        Data Standards and Register Design.
      </p>
    </section>
  )
}

export default CompetenciesTable
