import { User } from "oidc-client-ts"
import { AxiosError } from "axios"
import { AuthContextProps } from "react-oidc-context"
import * as _ from "lodash"

const callSilentRenewFor401Errors = _.before(4, (auth: AuthContextProps) => {
  return auth
    .signinSilent()
    .then(user => {
      return Promise.resolve(user)
    })
    .catch(err => {
      console.warn("Silent sign-in failed", err)
      auth.signinRedirect()
      return Promise.resolve()
    })
})

export const handle401Error = (
  error: AxiosError,
  auth: AuthContextProps
): Promise<User | void | null> => {
  return callSilentRenewFor401Errors(auth)
}
