import React, { useEffect, useState } from "react"
import styles from "./log.module.scss"
import { ScenarioStatus, Step, UserActionTypes } from "../../_models/Scenarios"

import cx from "classnames"
import LogStep from "./LogStep/V1/LogStep"
import LoadingSpinner from "../LoadingSpinner"
import { ShowDetailsOnClickHandler } from "./LogStep/V1/LogStepNote"

export interface LogProps {
  steps: Array<Step> | undefined
  title: string
  submitLog?: boolean
  scenarioStatus?: ScenarioStatus
  continueTestExecution?: any
  isLoading?: boolean
  showDetailsOnClick?: ShowDetailsOnClickHandler
}

const Log = (props: LogProps) => {
  const [browserInteract, setBrowserInteract] = useState<boolean>(false)

  useEffect(() => {
    props.steps &&
      browserInteract &&
      props.steps[props.steps.length - 1] &&
      setBrowserInteract(false)
  }, [props.steps, browserInteract])

  const handleInteractWithBrowserOnClick = () => {
    setBrowserInteract(true)
  }

  const checkIfComplete = (scenarioStatus: ScenarioStatus) => {
    switch (scenarioStatus) {
      case ScenarioStatus.Success:
      case ScenarioStatus.Failure:
        return true
      default:
        return false
    }
  }

  const isAwaitingAuthRedirect = (step: Step, idx, steps) => {
    const isRedirectStep = step.userAction?.actionType === UserActionTypes.Redirect
    const isWaitingForRedirectHandle = !steps
      .slice(idx)
      .find((step: Step) =>
        step.name.toLowerCase().includes("handle authorize redirect request")
      )
    return isRedirectStep ? isWaitingForRedirectHandle : true
  }

  return (
    <div className={styles.log__wrapper}>
      <h3
        className={cx(styles.visualyHidden, {
          [styles["log__heading--small"]]: props.submitLog,
        })}
      >
        {props.submitLog ? props.title : "Scenario Log"}
      </h3>
      <div role="alert" aria-live="assertive">
        {props.scenarioStatus === ScenarioStatus.InProgress ? (
          <p role="alert" className={styles.srOnly}>
            Scenario is in progress
          </p>
        ) : null}
      </div>
      {props.isLoading ? (
        <LoadingSpinner isLogSpinner />
      ) : (
        <table>
          <caption className={styles.visualyHidden}>{props.title}</caption>
          <>
            <thead>
              <tr>
                <th className={styles.log__columnTest} scope="col">
                  Tests
                </th>
                <th className={styles.log__columnNotes} scope="col">
                  Notes
                </th>
                <th className={styles.log__columnStatus} scope="col">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {props.steps && props.steps.length === 0 && (
                <tr>
                  <td>Scenario has not started </td>
                  <td />
                  <td />
                </tr>
              )}
              {props.steps &&
                props.steps.map((step, idx, array) => (
                  <LogStep
                    key={idx}
                    step={step}
                    id={idx}
                    isStepActive={
                      props.scenarioStatus === ScenarioStatus.InProgress &&
                      isAwaitingAuthRedirect(step, idx, array)
                    }
                    browserInteract={browserInteract}
                    interactWithBrowserOnClick={handleInteractWithBrowserOnClick}
                    continueTestExecution={props.continueTestExecution}
                    showDetailsOnClick={props.showDetailsOnClick}
                  />
                ))}
              {}
              {props.scenarioStatus === ScenarioStatus.Interrupted ? (
                <tr className={styles["step--highlighted"]}>
                  <td>Scenario interrupted by user</td>
                  <td></td>
                  <td></td>
                </tr>
              ) : null}
              {props.scenarioStatus && checkIfComplete(props.scenarioStatus) ? (
                <tr className={styles["step--highlighted"]}>
                  <td>Scenario finished running</td>
                  <td></td>
                  <td></td>
                </tr>
              ) : null}
            </tbody>
          </>
        </table>
      )}
    </div>
  )
}

export default Log
