import React, { useState, useEffect } from "react"
import Modal from "react-modal"
import styles from "./errormessage.module.scss"
import Button from "../Button"
import SuspendedIcon from "../../assets/alert__warning__solid.svg"

interface ErrorMessageProps {
  heading: string
  buttonText: string
  children?: any
  isOpen: boolean
}

const ErrorMessage = (props: ErrorMessageProps) => {
  const [open, setOpen] = useState(false)

  var isOpen = props.isOpen
  var buttonText = props.buttonText

  const handleClose = (reason: string) => {
    if (reason !== "fromButton") return
    setOpen(false)
  }

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  return (
    <div>
      <Modal
        isOpen={open}
        onRequestClose={handleClose}
        className={styles.errormessage}
        contentLabel={props.heading}
        appElement={document.getElementById("root")}
      >
        <div className="errormessage__icon">
          <img
            className={styles.icon}
            src={SuspendedIcon}
            aria-hidden="true"
            alt="Suspended Icon"
          />
        </div>
        <div className={styles.errormessage__main}>
          {!(props.heading === "") ? (
            <h4 className={styles.errormessage__heading}>{props.heading}</h4>
          ) : (
            <p></p>
          )}
          {props.children}
          <div className={styles.errormessage__buttons}>
            <span onClick={() => handleClose("fromButton")}>
              <Button text={buttonText}></Button>
            </span>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ErrorMessage
