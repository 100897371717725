import React from "react"
import styles from "./subheader.module.scss"

interface SubHeaderProps {
  title: string | undefined
  subtitle?: string | undefined
}

const SubHeader = (props: SubHeaderProps) => {
  return (
    <div className={styles.subHeader}>
      <div className={styles.contentWrapper}>
        <p>
          <strong>{props.title}</strong>
          {props.subtitle && (
            <span className={styles.subtitle}> | {props.subtitle}</span>
          )}
        </p>
      </div>
    </div>
  )
}

export default SubHeader
