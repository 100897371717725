import React, { useState, useEffect } from "react"
import { useAuth } from "react-oidc-context"

import {
  Scenario,
  ScenarioStatus,
  Step,
  UserActionTypes,
  StepStatus,
} from "../../_models/Scenarios"
import styles from "./submit.module.scss"
import service from "../../_services/serviceCall"

import SubHeader from "../../_components/SubHeader"
import Button from "../../_components/Button"
import BreadCrumbs from "../../_components/BreadCrumbs"
import Alert, { AlertType } from "../../_components/Alert"
import Accordion from "../../_components/Accordion"
import Modal2 from "../../_components/Modal2"
import LoadingSpinner from "../../_components/LoadingSpinner"
import { useNavigate } from "react-router-dom"

import cx from "classnames"
import TestPlanVersion from "../../_util/TestPlanVersion"
import { DataRecipientScenarioName } from "../../_models/ScenarioNames"
import LogFactory from "../../_components/Log/LogFactory"
import CompetenciesFactory from "../../_components/CompetenciesTable/CompetenciesFactory"
import { handle401Error } from "../../_util/error-handling"

export interface ISubmitPageProps {
  scenarios: any
  conformanceID: string | undefined
  testPlanId: any
  testPlanName: any
  sector: string | undefined
  scenarioStarted: any
  participantName: string | undefined
  onClickHome?: () => void
}

function SubmitPage(props: ISubmitPageProps) {
  const [scenarios, setScenarios]: [Scenario[], any] = useState([])
  const [successfulStatus, setSuccessfulStatus]: [number, any] = useState(0)
  const [failureStatus, setFailureStatus]: [number, any] = useState(0)
  const [inProgressStatus, setInProgressStatus]: [number, any] = useState(0)
  const [notStartedStatus, setNotStartedStatus]: [number, any] = useState(0)
  const [interruptedStatus, setInterruptedStatus]: [number, any] = useState(0)
  const [planIncomplete, setPlanIncomplete]: [boolean, any] = useState(false)
  const [loaded, setLoaded]: [boolean, any] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [isAdr, setIsAdr]: [boolean, any] = useState(false)
  const [error, setError] = useState<Error>()

  const auth = useAuth()
  let navigate = useNavigate()

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  function hideWaitingSteps(steps: Step[], scenarioStatus: ScenarioStatus) {
    const lastStep = steps[steps.length - 1]
    if (steps.length > 1) {
      if (lastStep.userAction?.actionType === UserActionTypes.WaitingContinuation) {
        return steps
      } else if (
        lastStep.groupId &&
        steps
          .filter(step => step.groupId === lastStep.groupId)
          .every(step => step.status === StepStatus.Waiting)
      ) {
        return steps
      } else {
        if (
          scenarioStatus !== ScenarioStatus.InProgress &&
          scenarioStatus !== ScenarioStatus.NotStarted
        ) {
          return steps.filter(step => step.status !== StepStatus.Waiting)
        } else {
          return steps
        }
      }
    } else {
      return steps
    }
  }

  function setScenarioStatus(scenarioStatus: string) {
    switch (scenarioStatus) {
      case "Success":
        setSuccessfulStatus(prevState => prevState + 1)
        break
      case "Failure":
        setFailureStatus(prevState => prevState + 1)
        break
      case "InProgress":
        setInProgressStatus(prevState => prevState + 1)
        break
      case "Interrupted":
        setInterruptedStatus(prevState => prevState + 1)
        break
      default:
        setNotStartedStatus(prevState => prevState + 1)
    }
  }

  useEffect(() => {
    document.title = "Conformance Test Suite - Review and submit"
    document.getElementById("main-app-heading")?.focus()

    async function getScenario(scenarioId) {
      return (
        auth.user &&
        service(auth.user.access_token, props.conformanceID)
          .getScenario(scenarioId)
          .then(res => res.data)
          .catch(err => {
            setError(err)
            err.response?.status === 401 && handle401Error(err, auth)
          })
      )
    }

    Promise.all<Scenario>(
      props.scenarios
        ?.filter(s => !!s)
        ?.map(scenario => {
          return getScenario(scenario.id)
        }) || []
    ).then(_scenarios => {
      const firstScenario = _scenarios?.at(0)
      setIsAdr(
        firstScenario?.name === DataRecipientScenarioName.CoreCompetenciesScenario
      )
      const realScenarios = _scenarios?.filter(s => !!s) || []
      setScenarios([...realScenarios].sort((a, b) => (a.id < b.id ? -1 : 1)))
      realScenarios.forEach(s => setScenarioStatus(s?.status))

      //check if any scenarios are not successful
      let planIncomplete =
        realScenarios.length === 0 ||
        realScenarios.filter(function (scenario) {
          return scenario?.status !== ScenarioStatus.Success
        }).length > 0
      setPlanIncomplete(planIncomplete)

      setLoaded(true)
    })
  }, [auth, auth.user, props.conformanceID, props.scenarios])

  function submitTestPlan() {
    auth.user &&
      service(auth.user.access_token, props.conformanceID)
        .submitTestPlan(props.testPlanId)
        .then(() => {
          navigate("/submitted")
        })
        .catch(err => {
          setError(err)
          err.response?.status === 401 && handle401Error(err, auth)
        })
  }

  return (
    <div>
      <SubHeader title={props.participantName} subtitle={props.sector} />
      <div className={styles.contentWrapper}>
        {error && (
          <div className={styles.contentWrapper}>
            <Alert title="Error loading Participant" alertType={AlertType.Error}>
              <p>{error.message || error.toString()}</p>
              <Button text="Go Back" onClick={props.onClickHome}></Button>
            </Alert>
          </div>
        )}
        {!error && loaded && (
          <div>
            <Modal2
              isOpen={modalIsOpen}
              closeModal={() => closeModal()}
              onRequestClose={() => closeModal()}
              heading="Are you sure you want to submit?"
              buttonText="Yes, submit"
              onSubmitClick={() => submitTestPlan()}
            >
              <p className={styles.centeredText}>
                You will need to contact ACCC if you <br /> want to reset your test
                plan.
              </p>
            </Modal2>
            <BreadCrumbs text="Review and submit" />
            <h2 id="main">Review and submit</h2>
            <span>
              <strong>{props.testPlanName}</strong>
            </span>
            <Alert
              title={planIncomplete ? "Partially complete" : "Successfully complete"}
              alertType={planIncomplete ? AlertType.Warning : AlertType.Success}
            >
              <div>
                <p>
                  {successfulStatus !== 0 && (
                    <React.Fragment>
                      <span className={styles.scenario__counter}>
                        <b>{successfulStatus + "/" + scenarios.length}</b> scenarios
                        successful
                      </span>
                    </React.Fragment>
                  )}

                  {failureStatus !== 0 && (
                    <React.Fragment>
                      <span className={styles.scenario__counter}>
                        <b>{failureStatus + "/" + scenarios.length}</b> scenarios
                        failed
                      </span>
                    </React.Fragment>
                  )}

                  {inProgressStatus !== 0 && (
                    <React.Fragment>
                      <span className={styles.scenario__counter}>
                        <b>{inProgressStatus + "/" + scenarios.length} </b> scenarios
                        in progress
                      </span>
                    </React.Fragment>
                  )}

                  {notStartedStatus !== 0 && (
                    <React.Fragment>
                      <span className={styles.scenario__counter}>
                        <b>{notStartedStatus + "/" + scenarios.length} </b> scenarios
                        not started
                      </span>
                    </React.Fragment>
                  )}

                  {interruptedStatus !== 0 && (
                    <React.Fragment>
                      <span className={styles.scenario__counter}>
                        <b>{interruptedStatus + "/" + scenarios.length} </b>{" "}
                        scenarios interrupted
                      </span>
                    </React.Fragment>
                  )}
                </p>
              </div>
              <span
                className={cx(styles.submit__date, {
                  [styles["submit__date--purple"]]: planIncomplete,
                })}
              />
            </Alert>
            <Accordion title="Show test plan details">
              {scenarios.map((scenario, idx) => (
                <div key={idx}>
                  {(isAdr ||
                    TestPlanVersion.compareVersions(
                      scenario?.testPlan?.version || "",
                      "5.0.0"
                    ) >= 0) &&
                    scenario?.steps &&
                    CompetenciesFactory.build(
                      new TestPlanVersion(
                        scenario?.testPlan?.version || "",
                        isAdr ? "ADR" : "DH"
                      ),
                      scenario?.steps,
                      scenario?.status
                    )}
                  {LogFactory.build(
                    new TestPlanVersion(
                      scenario?.testPlan?.version || "",
                      isAdr ? "ADR" : "DH"
                    ),
                    hideWaitingSteps(scenario?.steps, scenario.status),
                    scenario.name,
                    scenario?.status,
                    undefined,
                    false,
                    undefined,
                    true
                  )}
                </div>
              ))}
            </Accordion>
            <section className={styles.submitContent}>
              <h4>Submitting your test plan</h4>
              <p>
                You should aim to pass all test scenarios before submitting the test
                plan. If you believe tests do not apply to your system, you can
                submit and provide an explanation to ACCC (
                <a href="mailto:CDRTechnicalOperations@accc.gov.au">
                  CDRTechnicalOperations@accc.gov.au
                </a>
                ). Once you have submitted the test plan, you cannot run the tests
                again unless you request ACCC to reset the test plan.
              </p>
              <div className={styles.submit__buttons}>
                <Button
                  text="Back to test plan"
                  secondary={true}
                  onClick={() => navigate("/")}
                />
                <Button text="Submit test plan" onClick={() => openModal()} />
              </div>
            </section>
          </div>
        )}
        {!error && !loaded && (
          <div className={styles.submit__loading}>
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  )
}

export default SubmitPage
