import * as React from "react"
import { useEffect, useState } from "react"
import service from "../../_services/serviceCall"
import { useLocation } from "react-router-dom"

// V4.0.2 and earlier
const createFormPostBodyFromFragment = (hash: string) => {
  // https://openid.net/specs/openid-connect-core-1_0.html#FragmentNotes
  const params = {}
  const postBody = hash.substring(1)
  const regex = /([^&=]+)=([^&]*)/g
  let m
  while ((m = regex.exec(postBody))) {
    params[decodeURIComponent(m[1])] = decodeURIComponent(m[2])
  }
  return postBody
}

const EngineRedirectPage = () => {
  const [executed, setExecuted] = useState(false)
  const [errorExecuting, setErrorExecuting] = useState(false)

  let location = useLocation()
  const { hash, pathname, search } = location

  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const url = pathname.replace("/cts", "")

    const fetchData = async () => {
      setErrorExecuting(false)
      try {
        if (searchParams.has("response")) {
          await service("").sendGetEngineRedirect(url + search)
        } else {
          const postBody = createFormPostBodyFromFragment(hash)
          await service("").sendEngineRedirect(url, postBody)
        }
        setExecuted(true)
      } catch {
        setErrorExecuting(true)
      }
    }

    fetchData()
  }, [pathname, hash, search])

  return (
    <div>
      {executed ? (
        <p>
          Authorization redirect handled successfully. Close this tab and return to
          CTS.
        </p>
      ) : errorExecuting ? (
        <p>Error while processing request.</p>
      ) : (
        <p>Handling incoming request.</p>
      )}
    </div>
  )
}

export default EngineRedirectPage
