import React, { useState } from "react"
import ToTheTop from "../../assets/to-the-top.svg"
import styles from "./ScrollButton.module.scss"

const ScrollButton = () => {
  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    })
  }

  window.addEventListener("scroll", toggleVisible)

  return (
    <img
      title="Go to the top"
      src={ToTheTop}
      alt="scroll to the top"
      className={styles.toTheTopButton}
      onClick={scrollToTop}
      style={{ display: visible ? "block" : "none" }}
    />
  )
}

export default ScrollButton
