import { Step } from "../../../_models/Scenarios"
import styles from "./../log.module.scss"
import React from "react"

interface ManualActionNoteProps {
  step: Step
  isLastStep: boolean
  isScenarioInterrupted: boolean
  isSubmitLog?: boolean
  browserInteract: boolean
  interactWithBrowserOnClick: () => void
}

export const ManualActionNote = (props: ManualActionNoteProps) => {
  return (
    <td>
      {!props.isLastStep || props.isSubmitLog || props.isScenarioInterrupted ? (
        <span>Run manual browser step</span>
      ) : (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={props.step.userAction?.data.RedirectUrl}
          onClick={props.interactWithBrowserOnClick}
          id="run-manual-browser-step"
          role="alert"
        >
          Run manual browser step{" "}
          <span className={styles.visuallyHidden}>(opens new browser tab)</span>
        </a>
      )}
      {props.browserInteract && props.isLastStep && (
        <div className={styles.interactMessage}>
          <div>
            <b>Test started</b>
          </div>
          <p>This may take several minutes and will refresh when complete.</p>
        </div>
      )}
    </td>
  )
}
