import React from "react"
import Modal from "react-modal"
import styles from "./modal.module.scss"
import Button from "../Button"
import SuspendedIcon from "../../assets/alert__warning__solid.svg"

interface ModalProps2 {
  heading: string
  buttonText: string
  children?: any
  isOpen: boolean
  closeModal: () => void
  onSubmitClick: () => void
  onRequestClose: () => void
}

const Modal2 = (props: ModalProps2) => {
  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.closeModal}
        className={styles.modal}
        contentLabel={props.heading}
        appElement={document.getElementById("root")}
      >
        <div className="modal__icon">
          <img
            className={styles.icon}
            src={SuspendedIcon}
            aria-hidden="true"
            alt="Alert Icon"
          />
        </div>
        <div className={styles.modal__main}>
          <h4 className={styles.modal__heading}>{props.heading}</h4>
          {props.children}
          <div className={styles.modal__buttons}>
            <button className={styles.button__cancel} onClick={props.closeModal}>
              No, go back
            </button>
            <span onClick={() => props.onSubmitClick()}>
              <Button text={props.buttonText}></Button>
            </span>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Modal2
