import React, { useEffect, useState } from "react"
import styles from "./steptimer.module.scss"

export const TIME_MULTIPLIER = 60
export const SECOND = 1000
export const MINUTE = SECOND * 60

const getTimeRemaining = (deadline: Date): number => {
  const timeRemaining = +deadline - Date.now()
  return timeRemaining > 0 ? timeRemaining : 0
}

export const StepTimer = ({
  deadline = new Date(),
  active = true,
  onDeadlined = () => {},
}) => {
  const incomingTime = getTimeRemaining(deadline)
  const [time, setTime] = useState<number>(incomingTime)
  const [isActive, setIsActive] = useState(active)

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isActive) {
        return
      }

      if (time <= 0) {
        setIsActive(false)
        clearInterval(interval)
        onDeadlined()
        return
      }

      const nextTimeStep = getTimeRemaining(deadline)
      return setTime(nextTimeStep)
    }, 400)

    const nextTimeStep = getTimeRemaining(deadline)
    setTime(nextTimeStep)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onDeadlined, deadline, isActive])

  return (
    <span className={styles.stepTimer}>
      {`${Math.floor((time / MINUTE) % TIME_MULTIPLIER)}`}:
      {`${Math.floor((time / SECOND) % TIME_MULTIPLIER)}`.padStart(2, "0")}
    </span>
  )
}
