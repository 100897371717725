import React from "react"
import cx from "classnames"
import styles from "./alert.module.scss"

import SuccessIcon from "../../assets/alert__success__stroke.svg"
import FailureIcon from "../../assets/alert__failure__stroke.svg"
import WarningIcon from "../../assets/alert__warning__solid.svg"

export enum AlertType {
  Success,
  Error,
  Warning,
  // Info,
}

interface AlertProps {
  title: string
  alertType: AlertType
  children?: any
  alertRef?: any
}

function alertIcon(alertType) {
  switch (alertType) {
    case AlertType.Success:
      return SuccessIcon
    case AlertType.Warning:
      return WarningIcon
    case AlertType.Error:
      return FailureIcon
  }
}

const Alert = (props: AlertProps) => {
  return (
    <div className={`alert ${styles.alert__container}`} id="alert-container">
      {/* Top Border */}
      <div
        className={cx(styles.alert__topBorder, {
          [styles.alert__topBorderFailure]: props.alertType === AlertType.Error,
          [styles.alert__topBorderSuccess]: props.alertType === AlertType.Success,
          [styles.alert__topBorderWarning]: props.alertType === AlertType.Warning,
        })}
      ></div>

      {/* Icon */}
      <img className={styles.alert__icon} src={alertIcon(props.alertType)} alt="" />

      {/* Content */}
      <div
        className={styles.alert__content}
        ref={props.alertRef}
        id="alert-focus"
        tabIndex={-1}
      >
        {/* Heading */}
        <div aria-live="assertive">
          <h3 role="alert">{props.title}</h3>
        </div>
        {props.children}
      </div>
    </div>
  )
}

export default Alert
