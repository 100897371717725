import styles from "./../../log.module.scss"
import React from "react"
import { Step } from "../../../../_models/Scenarios"
import alertInfoSolid from "../../../../assets/alert__info__solid.svg"

interface LogStepNameProps {
  step: Step
}

export function checkIfNested(step: Step) {
  if (step.groupId && step.userAction === null) {
    if (
      step.cdsRule.title !==
      "Awaiting on user to continue execution of test scenario"
    )
      return true
  } else {
    return false
  }
}

function getName(name: string) {
  switch (name) {
    case "Awaiting incoming requests (may take several minutes)":
      return (
        <div>
          <strong>Polling endpoints</strong>
          <br />
          Some participants do not require all 3 polling steps.
        </div>
      )
    case "Important: Some configurations do not require all 3 tests. You can continue, only if all expected tests for your organisation have completed.":
      return (
        <div className={styles.step__continueWrapper}>
          <img className={styles.step__icon} src={alertInfoSolid} alt="alert icon" />
          <div>
            You can continue the scenario if the expected polling steps for your
            organisation have completed.
          </div>
        </div>
      )
    default:
      return <span>{name}</span>
  }
}

const LogStepName = (props: LogStepNameProps) => {
  return (
    <td className={styles.step__name}>
      {checkIfNested(props.step) ? <span className={styles.nested} /> : null}
      {getName(props.step.name)}
    </td>
  )
}

export default LogStepName
